<template>
	<section class="background-div">
		<div class="card-container">
			<div>
				<div>
					<div class="card card-info">
						<div class="card-header text-center">
							<h4 class="m-0">Recuperar conta</h4>
						</div>
						<div class="card-body">
							<b-form @submit.prevent="sendEmail">
								<div>
									<b-form-input
										id="email"
										v-model="email"
										class="form-control input-sm chat-input"
										placeholder="Digite seu e-mail"
									/>
								</div>
                                <div class="login-msg" v-if="success || error">
                                    <span v-if="success" class="success-msg">
                                        {{ info }}
                                    </span>
                                    <span v-else-if="error" class="error-msg">
                                        {{ error }}
                                    </span>
                                    <br v-else>
                                </div>
                                <div class="login-msg" v-else>
                                    <br>
                                </div>
                                <br>
								<div class="text-center">
									<button
										type="submit"
										class="btn btn-primary w-100"
										:disabled="!email"
									>
										Enviar
									</button>
								</div>
							</b-form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<style scoped>
	section {
		height: 100vh;
		width: 100vw;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.card-container {
		width: 50%;
		max-width: 440px;
	}

	.login-msg {
		text-align: center;
		padding-top: 0.5rem;
	}

	.error-msg {
		color: red;
	}

	.success-msg {
		color: #008000;
	}

	.btn-primary {
		background-color: var(--cal-header-color);
		border-color: var(--cal-header-color);
	}

	.background-div {
		position: absolute;
		width: 100%;
		height: 100vh;  /* Ajusta según sea necesario */
		overflow: auto !important;
		background-image: url('../assets/img/background.png');
		background-size: cover;  /* Ajusta el tamaño de la imagen */
		background-position: center;  /* Centra la imagen */
		background-repeat: no-repeat;  /* Evita que la imagen se repita */
	}
</style>

<script>
	import { AuthService } from "@/services/auth";

	export default {
		data () {
			return {
				email: "",
				error: "",
				info: "",
				success: false,
				authService: new AuthService,
				backgroundImageUrl: "../assets/img/background.png"
			};
		},

		methods: {
			async sendEmail () {
				try {
					if (!this.email) return;

					this.error = "";
					this.info = "Enviados dados...";
					this.success = true;
					const response = await this.authService.forget({ email: this.email });
					this.email = "";
					if (response && response === "OK") {
						this.info = "Sucesso...";
						this.$router.replace(this.$route.params.next || "/Login");
					}
				} catch (error) {
					this.success = false;
					this.error = error?.response?.data?.message || "Erro desconhecido, por favor tente novamente.";
				}
			}
		}
	};
</script>
