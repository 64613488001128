<!-- eslint-disable vue/multi-word-component-names -->
<script>
	import ShowDay from "../components/ShowDay.vue";
	import LabelDay from "../components/LabelDay.vue";
	import TitleMonth from "../components/TitleMonth.vue";
	import TotalsMonth from "../components/TotalsMonth.vue";
	import DataTable from "../components/DataTable.vue";

	import { mapState } from "vuex";
	import { possuiPermissao } from "../helpers/permissions";
	import { DashboardService } from "@/services/dashboard";
	import { PaymentScheduleService } from "@/services/paymentSchedule";
	import { AccountService } from "@/services/account";
	import { AccountClassService } from "@/services/accountClass";
	import { MessageService } from "@/services/message";
	import { CategoryExpensesService } from "@/services/categoryExpenses";
	import { numberFormatted, stringToNumber, validateNumber, stringToDateUTC, formatterSimple } from "@/helpers/common";
	import { hasFieldsWithErrors } from "@/helpers/validators";

	import dayjs from "dayjs";
	import "dayjs/locale/pt-br";
	import Datepicker from "vuejs-datepicker";
	import { ptBR } from "vuejs-datepicker/dist/locale";

	import Toast from "@/components/Toast";
	import ChartMedia from "@/components/charts/ChartMedia.vue";
	import CardTransaction from "@/components/CardTransaction.vue";

	export default {
		components: {
			ShowDay,
			LabelDay,
			TitleMonth,
			TotalsMonth,
			DataTable,
			Datepicker,
			Toast,
			ChartMedia,
			CardTransaction
		},

		data () {
			return {
				showFiltersForm: false,
				hideHeaderModal: true,
				ptBR,
				diasSemana: [],
				calendario: [],
				optionsTitleMes: {},
				totalesMes: {},
				showTotales: false,
				dateSearch: new Date(),
				isLoading: true,
				dashboardService: new DashboardService(),
				possuiPermissao,
				paymentScheduleService: new PaymentScheduleService(),
				accountService: new AccountService(),
				accountClassService: new AccountClassService(),
				categoryExpensesService: new CategoryExpensesService(),
				messageService: new MessageService(),

				titleModalPaymentsOfDay: "",
				linhas: [],
				paymentScheduleOfDay: [],
				colunas: [
					"Referência",
					"Conta",
					"Categoria",
					"Data",
					"Valor",
					"Observações"
				],
				colsDataCard: {
					0: {
						idx: 1,
						label: "Conta",
						textAlign: "left",
						cols: 6,
						visible: true
					},
					1: {
						idx: 2,
						label: "Categoria",
						textAlign: "left",
						cols: 6,
						visible: true
					},
					2: {
						idx: 3,
						label: "Data",
						textAlign: "center",
						cols: 3,
						visible: true
					},
					3: {
						idx: 4,
						label: "valor",
						textAlign: "right",
						cols: 3,
						visible: true
					},
					4: {
						idx: 0,
						label: "Referência",
						textAlign: "left",
						cols: 6,
						visible: true
					},
					5: {
						idx: 0,
						label: "",
						textAlign: "left",
						cols: 3,
						visible: false
					},
					6: {
						idx: 5,
						label: "Observações",
						textAlign: "left",
						cols: 12,
						visible: true
					},
					7: {
						idx: null,
						label: "",
						textAlign: "left",
						cols: 6,
						visible: false
					}

				},
				alignColumnsTable: [
					"left",
					"left",
					"left",
					"left",
					"right",
					"left"
				],
				categoryExpenses: null,
				errMsg: "Nenhum agendamento cadastrado!",
				statusModalPayment: false,

				optionModalAccount: [],
				paymentExecuteDate: new Date(),
				paymentExecuteAccount: null,
				paymentExecuteAmount: numberFormatted(0),
				paymentExecuteComments: "",
				paymentScheduleExecute: [],
				invalidForm: true,

				config: {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				},

				optionsFilterCategoryExpenses: [],
				optionsFilterAccount: [],
				filterAccount: [],
				filterCategoryExpenses: [],
				filterComments: "",

				sortTable: {
					sortBy: 3,
					order: -1
				},
				accountPayBalance: "0,00",
				insufficientBalance: false,

				methods_pay: [],
				insufficientBalanceAccount: true,
				methodsPayAmount: 0,
				balanceZero: false,
				havePermissionMultiAccountPay: false,
				invalidFormMulti: true,
				idPaymentScheduleExecutePayOne: null,
				acoes: possuiPermissao("DASHBOARD_P_AGENDAMENTOS") || possuiPermissao("DASHBOARD_U_AGENDAMENTOS") || possuiPermissao("DASHBOARD_D_AGENDAMENTOS"),
				notAdvancedEdit: !possuiPermissao("DASHBOARD_ADVANCED_EDIT"),

				titleModalSchedule: "",
				editing: true,
				permiteParcelas: false,
				schedulePaymentUpsert_invalidForm: true,
				// schedulePaymentUpsert_idPaymentSchedule: idPaymentSchedule,
				schedulePaymentUpsert_comments: "",
				schedulePaymentUpsert_reference: "",
				schedulePaymentUpsert_idCategoryExpense: null,
				schedulePaymentUpsert_idAccountSchedule: null,
				schedulePaymentUpsert_date: new Date(),
				schedulePaymentUpsert_amount: [],
				schedulePaymentUpsert_amount_string: [],
				schedulePaymentUpsert_paymentDate: new Date(),
				schedulePaymentUpsert_installments: 1,
				schedulePaymentUpsert_valor: 0,
				schedulePaymentUpsert_valor_string: "0,00",
				loading: false,
				optionModalCategoryExpense: [],
				paymentScheduleOfDayAll: [],
				subscriptionActive: true,
				messageToast: "",
				variantToast: "",
				optionsDashboardView: [
					{
						text: "--- Selecione ---",
						value: null,
						perm: null
					},
					{
						text: "Totais do mês",
						value: 1,
						perm: "DASHBOARD_V_TOTALS"
					},
					{
						text: "Análise comparativa",
						value: 2,
						perm: "DASHBOARD_V_CHART_GERAL"
					},
					{
						text: "Despesas por categorias",
						value: 3,
						perm: "DASHBOARD_V_CHART_CATEGORY"
					},
					{
						text: "Receitas por categorias",
						value: 4,
						perm: "DASHBOARD_V_CHART_CATEGORY_INCOME"
					}
				],
				dashboardView: null,
				optionsDashboardViewPermission: [],
				forceChartUpdate: 0,
				profileUserConfig: {},
				isMobile: false
				// idPaymentSchedule: null
			};
		},

		computed: mapState(["sideBarWidth"]),

		beforeDestroy () {
			window.removeEventListener("resize", this.checkIfMobile);
		},

		async mounted () {
			this.checkIfMobile();
			window.addEventListener("resize", this.checkIfMobile);

			this.profileUserConfig = localStorage.profileUserConfig ? JSON.parse(localStorage.profileUserConfig) : {};
			if (this.profileUserConfig && this.profileUserConfig.lastValueSelectedDashboardView)
				this.dashboardView = this.profileUserConfig.lastValueSelectedDashboardView || null;
			else {
				this.setLocalStorageItem("profileUserConfig", "lastValueSelectedDashboardView", null);
			}

			const optionsPermited = this.optionsDashboardView.reduce((acc, opt) => {
				const permission = opt.perm;
				const havePermission = permission ? possuiPermissao(permission) : null;
				if (havePermission || !permission)
					acc.push(opt);

				return acc;
			}, []);

			this.optionsDashboardViewPermission = optionsPermited.map(ele => ele);
			const existPermissionDashboard = this.optionsDashboardViewPermission.length && this.optionsDashboardViewPermission.some(opt => opt.value === this.profileUserConfig.lastValueSelectedDashboardView);
			this.dashboardView = existPermissionDashboard ? this.profileUserConfig.lastValueSelectedDashboardView
				: this.optionsDashboardViewPermission.length ? this.optionsDashboardViewPermission[0].value : null;


			this.isLoading = true;
			this.diasSemana = await this.dashboardService.weekDays({ date: this.dateSearch }) || this.getDiasSemana();
			const resCalendar = await this.dashboardService.calendar({ date: this.dateSearch }) || this.getCalendar(this.dateSearch);
			//const sendEmails = this.messageService.sendEmail({ date: this.dateSearch });
			this.calendario = resCalendar.calendar;
			this.optionsTitleMes = resCalendar.monthOptions;
			this.totalesMes = resCalendar.totales;
			this.isLoading = false;
			this.havePermissionMultiAccountPay = possuiPermissao("GER_P_MULTI_ACCOUNT");
			this.invalidFormMulti = this.havePermissionMultiAccountPay;
			const { subscriptionActive } = await JSON.parse(localStorage.params);
			this.variantToast = subscriptionActive;
			this.subscriptionActive = Boolean(!this.variantToast);
			if (this.variantToast === "error") {
				this.messageToast = "Renove assinatura para ativas as funcionalidades!";
			} else if (this.variantToast === "success") {
				this.messageToast = "Parabéns, solicite ativação das funcionalidades!";
			} else {
				this.messageToast = "Sua assinatura está vencida, faça renovação e evite bloqueo das funcionalidades!";
			}

			await this.findAccount();
			await this.findCategoryExpenses();
		},

		methods: {
			checkIfMobile	() {
				this.isMobile = window.innerWidth <= 768;
			},

			changeDashboardView () {
				this.setLocalStorageItem("profileUserConfig", "lastValueSelectedDashboardView", this.dashboardView);
				this.profileUserConfig = JSON.parse(localStorage.profileUserConfig);
			},

			setLocalStorageItem (obj, chave, value) {
				const objeto = JSON.parse(localStorage.getItem(obj)) || {};
				objeto[chave] = value;
				localStorage.setItem(obj, JSON.stringify(objeto));
			},

			sortTableBy (v) {
				this.sortTable = v;
				this.getPaymentScheduleOfDay();
			},

			filterDropDownActive () {
				this.showFiltersForm = !this.showFiltersForm;
			},

			async findMonth (value) {
				this.isLoading = true;
				if (value) {
					let year = this.dateSearch.getFullYear();
					let month = this.dateSearch.getMonth();

					switch (value) {
						case -1:
							if (month > 0) {
								month--;
							} else {
								month = 11;
								year--;
							}
							break;
						case 1:
							if (month < 11) {
								month++;
							} else {
								month = 0;
								year++;
							}
							break;
						default:
							break;
					}

					this.dateSearch = new Date(year, month, 1);
				}

				const resCalendar = await this.dashboardService.calendar({ date: this.dateSearch }) || this.getCalendar(this.dateSearch);
				this.calendario = resCalendar.calendar;
				this.optionsTitleMes = resCalendar.monthOptions;
				this.totalesMes = resCalendar.totales;
				this.isLoading = false;
			},

			getCalendar (date) {
				this.isLoading = true;
				const _date = new Date(date);
				const currentDate = new Date();
				const month = _date.getMonth();
				const year = _date.getFullYear();
				const dateNextMonth = new Date(year, month + 1, 1);
				dateNextMonth.setDate(dateNextMonth.getDate() - 1);
				const daysOfMonth = dateNextMonth.getDate();
				const firtDayOfMonth = (new Date(_date.getFullYear(), _date.getMonth(), 1)).getDay();
				const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
				const calendar = [];

				let day = 0;
				for (let idx = 0; idx <= 42; idx++) {
					if (idx < firtDayOfMonth || day >= daysOfMonth)
						calendar.push({ show: false });
					else {
						day++;
						const dayCalendar = (new Date(year, month, day));
						if (dayCalendar.getTime() === today.getTime())
							calendar.push({ day, value: "0,00", show: true, backColor: "white", color: "red", today: true, colorTextDay: "black" });
						else if (dayCalendar.getTime() < today.getTime())
							calendar.push({ day, value: "0,00", show: true, backColor: "grey", color: "black", colorTextDay: "black" });
						else
							calendar.push({ day, value: "0,00", show: true, backColor: "white", color: "black", colorTextDay: "black" });
					}
				}
				const monthOptions = {
					label: `${_date.toLocaleString("pt-BR", { month: "long" })}, ${year}`,
					color: "",
					backColor: "rgb(200, 198, 198, 1)"
				};
				this.isLoading = false;
				return { monthOptions, calendar };
			},

			getDiasSemana () {
				return [
					{backcolor: "grey", color: "black", label: "Sun"},
					{backcolor: "grey", color: "black", label: "Mon"},
					{backcolor: "grey", color: "black", label: "Tue"},
					{backcolor: "grey", color: "black", label: "Wed"},
					{backcolor: "grey", color: "black", label: "Thu"},
					{backcolor: "grey", color: "black", label: "Fri"},
					{backcolor: "grey", color: "black", label: "Sab"}
				];
			},

			handleShowTotales () {
				this.showTotales = !this.showTotales;
			},
			setDayPaymentSchedule (item) {
				this.item = item;
				const amount = stringToNumber(this.item.value);

				if (amount)
					this.getPaymentScheduleOfDay();
			},

			async getPaymentScheduleOfDay () {
				const item = this.item;
				this.filterAccount = [];
				this.filterCategoryExpenses = [];
				this.filterComments = null;

				const sortTable = this.sortTable;
				const dateSearchOfDay = new Date(this.dateSearch);
				dateSearchOfDay.setDate(item.day);
				this.titleModalPaymentsOfDay = `Agendamentos do día ${dayjs(dateSearchOfDay).locale("pt-br").format("DD-MM-YYYY")}`;
				const filters = {
					initDate: dayjs(dateSearchOfDay).locale("pt-br").format("YYYY-MM-DD"),
					endDate: dayjs(dateSearchOfDay).locale("pt-br").format("YYYY-MM-DD"),
					statusPaymentSchedule: [{ value: 0, nome: "Em aberto" }],
					dataPesquisa: [{ value: 1 }],
					sortTable
				};

				dateSearchOfDay.setDate(item.day);
				this.paymentScheduleOfDayAll = await this.paymentScheduleService.getPaymentScheduleByParams(filters);
				this.paymentScheduleOfDay = this.paymentScheduleOfDayAll.rows.map(rec => ({
					idPaymentSchedule: rec.idPaymentSchedule,
					selected: false,
					amount: rec.amount,
					idAccountSchedule: rec.idAccountSchedule,
					idCategoryExpense: rec.idCategoryExpense,
					comments: rec.comments,
					cols: [
						rec.reference || "",
						rec?.accountSchedule?.description || "",
						(this.categoryExpenses.filter(ce => ce.value === rec.idCategoryExpense))[0].nome || "",
						formatterSimple(rec.date),
						numberFormatted(rec.amount),
						rec.comments
					]
				}));
				if (this.paymentScheduleOfDay.length) {
					this.linhas = this.paymentScheduleOfDay.map(line => line);
					this.optionsFilterAccount = this.getOptionsAccount(this.paymentScheduleOfDayAll.rows);
					this.optionsFilterCategoryExpenses = this.getOptionsCategory(this.paymentScheduleOfDayAll.rows, this.categoryExpenses);
					this.showButonPayment();
					this.$refs.modalPaymentsOfDay.show();
				} else {
					this.findMonth();
				}
			},

			closeModal () {
				this.limparModal();
				this.$refs.modalPaymentsOfDay.hide();
			},

			getOptionsAccount (data) {
				return data.reduce((options, option) => {
					const existe = options.some(conta => conta.value === option.accountSchedule.idAccount);
					if (existe) return options;
					return [
						...options,
						{
							nome: option.accountSchedule.description,
							value: option.accountSchedule.idAccount
						}
					];
				}, []);
			},

			getOptionsCategory (data, categories) {
				return data.reduce((options, option) => {
					const existe = options.some(conta => conta.value === option.idCategoryExpense);
					if (existe) return options;

					return [
						...options,
						{
							nome: (categories.filter(ce => ce.value === option.idCategoryExpense))[0].nome || null,
							value: option.idCategoryExpense
						}
					];
				}, []);
			},

			async findAccount () {
				const result = await this.accountService.listAccount({offset: 0, limit: null}) || {};
				this.optionModalAccount = result.rows?.map(item => ({
					text: item.description,
					value: item.idAccount,
					permitePagamento: item.accountClass.automaticPay,
					balance: item.balance
				})) || [];
			},

			async findCategoryExpenses () {
				const result = await this.categoryExpensesService.listCategoryExpenses({offset: 0, limit: null}) || {};
				this.categoryExpenses = result.rows?.map(item => ({ nome: item.description, value: item.idCategoryExpense })) || [];

				this.selectCategoryExpenses = result.rows?.map(item => ({ nome: item.description, value: item.idCategoryExpense })) || [];
				this.optionModalCategoryExpense = result.rows?.map(item => ({ text: item.description, value: item.idCategoryExpense })) || [];
			},

			filterScheduleOfDay () {
				const optionComments = this.filterComments && this.filterComments.toLowerCase();
				this.linhas = this.paymentScheduleOfDay.reduce((rows, row) => {
					let sendToRows = true;
					if (this.filterAccount.length) {
						const options = this.filterAccount.map(op => op.value);
						sendToRows = options.includes(row.idAccountSchedule);
					}

					if (sendToRows && this.filterCategoryExpenses.length) {
						const options = this.filterCategoryExpenses.map(op => op.value);
						sendToRows = options.includes(row.idCategoryExpense);
					}

					if (sendToRows && this.filterComments) {
						const commentsRow = row.comments.toLowerCase();
						sendToRows = commentsRow.includes(optionComments);
					}

					if (!sendToRows) return rows;

					return [
						...rows,
						row
					];

				}, []);
			},

			formatter (date) {
				return dayjs(date).locale("pt-br").format("D [de] MMMM [de] YYYY");
			},

			validateState (ref) {
				this.invalidForm = hasFieldsWithErrors(this.veeFields);
				if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated))
					return !this.veeErrors.has(ref);

				return null;
			},

			previousModal () {
				this.$refs.modalPaymentExecute.hide();
			},

			showPayment (item) {
				this.limparModal();
				let amount = 0;
				this.statusModalPayment = false;
				if (typeof (item) === "string") {
					this.statusModalPayment = this.linhas.some(linha => linha.selected);
					this.linhas.forEach(linha => {
						if (linha.selected)
							amount = amount + parseFloat(linha.amount);
					});
				} else if (item && item.idPaymentSchedule) {
					const schedule = this.linhas.find(linha => linha.idPaymentSchedule === item.idPaymentSchedule);
					if (schedule) {
						this.statusModalPayment = true;
						amount = parseFloat(schedule.amount);
						this.idPaymentScheduleExecutePayOne = {
							idPaymentSchedule: schedule.idPaymentSchedule,
							valor: amount
						};
					}
				}
				if (!this.statusModalPayment) return;

				this.$refs.modalPaymentExecute.show();
				this.paymentExecuteAmount = numberFormatted(amount.toFixed(2));
			},

			limparModal () {
				this.paymentExecuteDate = new Date();
				this.paymentExecuteAccount = null;
				this.paymentExecuteComments = "";
				this.accountPayBalance = "0,00";
				this.methods_pay = [];
				this.invalidFormMulti = this.havePermissionMultiAccountPay;

				this.insufficientBalance = false;
				this.insufficientBalanceAccount = true;
				this.methodsPayAmount = 0;
				this.balanceZero = false;
				this.invalidFormMulti = true;
				this.idPaymentScheduleExecutePayOne = null;
			},

			showButonPayment () {
				this.statusModalPayment = this.linhas.some(linha => linha.selected);
			},

			async paymentExecute () {
				const baixaConfirmation = (await this.$swal({
					title: "Baixa",
					text: "Tem certeza que deseja executar a baixa?",
					type: "error",
					showCancelButton: true,
					cancelButtonColor: "#A6A8AB",
					confirmButtonColor: "#DD6B55",
					cancelButtonText: "Não",
					confirmButtonText: "Sim",
					reverseButtons: true
				})).value;

				if (!baixaConfirmation)
					return;

				let amount = 0;
				let idsPaymentSchedule = [];
				if (!this.idPaymentScheduleExecutePayOne) {
					idsPaymentSchedule = this.linhas.reduce((acc, linha) => {
						if (!linha.selected) return acc;

						amount = amount + parseFloat(linha.amount);
						return [
							...acc,
							{
								idPaymentSchedule: linha.idPaymentSchedule,
								valor: linha.amount
							}
						];
					}, []);
				}
				if (!idsPaymentSchedule.length) {
					idsPaymentSchedule.push(this.idPaymentScheduleExecutePayOne);
					amount = parseFloat(this.idPaymentScheduleExecutePayOne.valor);
				}
				const payload = {
					idsPaymentSchedule,
					paymentedDate: this.paymentExecuteDate,
					idPaymentAccount: this.paymentExecuteAccount,
					paymentAmount: amount,
					comments: this.paymentExecuteComments,
					methodsPay: this.methods_pay

				};

				this.$snotify.async("Aguarde...", "Executando baixas", async () => {
					try {
						await this.paymentScheduleService.executePayments({payload});

						this.forceChartUpdate = this.forceChartUpdate + 1;
						this.$refs.modalPaymentExecute.hide();
						this.$refs.modalPaymentsOfDay.hide();
						await this.findMonth();
						await this.limparModal();
						await this.findAccount();

						return {
							title: "Sucesso!",
							body: "Baixas realizadas com sucesso.",
							config: this.config
						};
					} catch (error) {
						const msgError = error.response.data.message || "Erro ao tentar executar baixas.";
						throw {
							title: "Falha!",
							body: msgError,
							config: this.config
						};
					}
				});
			},

			getAccountPayBalance (idAccount) {
				const accountHavePay = idAccount ? this.optionModalAccount.filter(account => account.value === idAccount)[0].permitePagamento : null;
				if (!accountHavePay) {
					this.insufficientBalanceAccount = false;
					this.insufficientBalance = false;
					this.accountPayBalance = "0,00";
					return;
				}

				const accountBalance = idAccount ? this.optionModalAccount.filter(account => account.value === idAccount)[0].balance : null;
				this.accountPayBalance = accountBalance ? numberFormatted(accountBalance) : "0,00";
				this.balanceZero = this.paymentExecuteAmount === this.methodsPayAmount;

				if (parseFloat(accountBalance) > 0)
					this.insufficientBalanceAccount = false;
				else
					this.insufficientBalanceAccount = true;

				if ((this.paymentExecuteAmount != 0 && this.paymentExecuteAmount > parseFloat(accountBalance)) || (this.schedulePaymentUpsert_valor != 0 && this.schedulePaymentUpsert_valor > parseFloat(accountBalance)))
					this.insufficientBalance = true;
				else
					this.insufficientBalance = false;

			},

			addMethotdPay (idAccount) {
				const accountSelectBalance = parseFloat(this.optionModalAccount.find(ele => ele.value === idAccount)?.balance || "0.00");
				this.paymentExecuteAccount = null;
				this.accountPayBalance = "0,00";

				if (this.methods_pay.length && this.methods_pay.some(ele => ele.account.idAccount === idAccount))
					return;

				let payAmountTotal = this.paymentExecuteAmount.replace(".", "");
				payAmountTotal = parseFloat(payAmountTotal.replace(",", "."));

				let payAmountMethod = payAmountTotal - this.methodsPayAmount;
				const description = (this.optionModalAccount.filter(ele => ele.value === idAccount))[0].text;

				if (payAmountMethod > accountSelectBalance)
					payAmountMethod = accountSelectBalance;

				this.methods_pay.push({
					account: { idAccount, description },
					amount_string: numberFormatted(payAmountMethod),
					amount: payAmountMethod
				});
				this.methodsPayAmount = this.methodsPayAmount + payAmountMethod;
				this.balanceZero = payAmountTotal === this.methodsPayAmount;

				if (payAmountTotal != this.methodsPayAmount)
					this.insufficientBalance = true;
				else
					this.insufficientBalance = false;

				this.invalidFormMulti = this.insufficientBalance || !this.methods_pay.length;
			},

			delMethodPay (index) {
				this.methods_pay.splice(index, 1);
				this.methodsPayAmount = 0;
				this.methods_pay.forEach(ele => {
					this.methodsPayAmount = this.methodsPayAmount + ele.amount ;
				});

				if (this.paymentExecuteAmount != parseFloat(this.methodsPayAmount))
					this.insufficientBalance = true;
				else
					this.insufficientBalance = false;

				this.invalidFormMulti = this.insufficientBalance || !this.methods_pay.length;
			},

			setNewValueMethodPay (amount, index) {
				const idAccount = this.methods_pay[index].account.idAccount;
				const accountSelectBalance = parseFloat(this.optionModalAccount.find(ele => ele.value === idAccount)?.balance || "0.00");
				let totalPay = parseFloat((this.methods_pay.reduce((total, ele, idx) => {
					if (index === idx)
						return total;

					return total + ele.amount;
				}, 0)).toFixed(2));

				if (amount) {
					const amountMethod = parseFloat(amount.replace(",", "."));
					totalPay += amountMethod;

					if (amountMethod > accountSelectBalance) {
						amount = accountSelectBalance.toString().replace(".", ",");
					} else {
						const paymentExecuteAmount = parseFloat(this.paymentExecuteAmount.replace(",", ".")).toFixed(2);
						if (totalPay > paymentExecuteAmount) {
							totalPay = (totalPay - amountMethod).toFixed(2);
							amount = (paymentExecuteAmount - totalPay).toFixed(2).toString().replace(".", ",");
						}
					}

					this.methods_pay[index].amount_string = validateNumber(amount);
					this.methods_pay[index].amount = parseFloat(this.methods_pay[index].amount_string.replace(",", "."));
				} else {
					return;
				}

				this.methodsPayAmount = 0;
				this.methods_pay.forEach(ele => {
					this.methodsPayAmount = this.methodsPayAmount + ele.amount;
				});

				const numberAmount = parseFloat(this.paymentExecuteAmount.replace(",", "."));
				this.balanceZero = numberAmount === this.methodsPayAmount || numberAmount < this.methodsPayAmount;
				if (numberAmount != parseFloat(this.methodsPayAmount))
					this.insufficientBalance = true;
				else
					this.insufficientBalance = false;

				this.invalidFormMulti = this.insufficientBalance || !this.methods_pay.length;
			},

			async updateValueMethodPay (value) {
				this.methods_pay.amount_string = await validateNumber(value);
				this.methods_pay.amount = this.methods_pay.amount_string.replace(",", ".");
			},

			async confirmaApagar (item_) {
				if (typeof (item_) === "string") {
					this.apagarVarios();
					return;
				}

				const deleteConfirmation = (await this.$swal({
					title: "Excluir",
					text: "Tem certeza que deseja cancelar o agendamento?",
					type: "error",
					showCancelButton: true,
					cancelButtonColor: "#A6A8AB",
					confirmButtonColor: "#DD6B55",
					imageWidth: 50,
					imageHeight: 50,
					cancelButtonText: "Não",
					confirmButtonText: "Sim",
					reverseButtons: true
				})).value;

				if (deleteConfirmation)
					this.apagar(item_.idPaymentSchedule);
			},

			async apagar (idPaymentSchedule) {
				this.$snotify.async("Aguarde...", "Apagando", async () => {
					try {
						const payload = { idsPaymentSchedule: [idPaymentSchedule] };
						await this.paymentScheduleService.deletePaymentSchedule({payload});
						this.updatePayOfDay(payload);

						return {
							title: "Sucesso!",
							body: "Agendamento cancelado com sucesso.",
							config: this.config
						};
					} catch (error) {
						const msgError = error.response.data.message || "Erro ao tentar deletar o agendamento!";
						throw {
							title: "Falha!",
							body: msgError,
							config
						};
					}
				});
			},

			async apagarVarios () {
				const idsPaymentSchedule = this.linhas.reduce((acc, item) => {
					if (item.selected)
						return [ ...acc, item.idPaymentSchedule ];

					return acc;
				}, []);

				if (!idsPaymentSchedule.length) return;

				const deleteConfirmation = (await this.$swal({
					title: "Excluir",
					text: "Tem certeza que deseja cancelar os agendamentos selecionados?",
					type: "error",
					showCancelButton: true,
					cancelButtonColor: "#A6A8AB",
					confirmButtonColor: "#DD6B55",
					imageWidth: 50,
					imageHeight: 50,
					cancelButtonText: "Não",
					confirmButtonText: "Sim",
					reverseButtons: true
				})).value;

				if (!deleteConfirmation)
					return;

				this.$snotify.async("Aguarde...", "Apagando", async () => {
					try {
						const payload = { idsPaymentSchedule };
						await this.paymentScheduleService.deletePaymentSchedule({payload});
						this.updatePayOfDay(payload);

						return {
							title: "Sucesso!",
							body: "Agendamento deletado com sucesso.",
							config: this.config
						};
					} catch (error) {
						const msgError = error.response.data.message || "Erro ao tentar cancelar o agendamento!";
						throw {
							title: "Falha!",
							body: msgError,
							config
						};
					}
				});
			},

			updatePayOfDay (payload) {
				const { idsPaymentSchedule } = payload;
				const newArray = this.paymentScheduleOfDay.reduce((acc, ele) => {
					if (!idsPaymentSchedule.includes(ele.idPaymentSchedule))
						acc.push(ele);

					return acc;
				}, []);
				this.paymentScheduleOfDay = newArray.map(line => line);
				this.linhas = this.paymentScheduleOfDay.map(line => line);
				this.findMonth();
				if (!this.linhas.length)
					this.closeModal();
			},

			async showSchedule (item_) {
				let item = {};
				if (item_.idPaymentSchedule)
					[ item ] = this.paymentScheduleOfDayAll.rows.filter(c => c.idPaymentSchedule === item_.idPaymentSchedule);

				if (item.cancelSchedule || item.paymentedDate) return;

				const {
					amount,
					comments,
					date,
					idAccountSchedule,
					idCategoryExpense,
					idPaymentSchedule,
					paymentDate,
					reference
				} = item;

				if (!idPaymentSchedule) {
					this.titleModalSchedule = "Adicionar Agendamento";
					this.editing = false;
				} else {
					this.titleModalSchedule = "Editar Agendamento";
					this.editing = true;

					this.schedulePaymentUpsert_idPaymentSchedule = idPaymentSchedule;
					this.schedulePaymentUpsert_idAccountSchedule = idAccountSchedule || "";
					this.schedulePaymentUpsert_comments = comments;
					this.schedulePaymentUpsert_reference = reference;
					this.schedulePaymentUpsert_date = stringToDateUTC(date);
					this.schedulePaymentUpsert_idCategoryExpense = idCategoryExpense;
					this.schedulePaymentUpsert_paymentDate = stringToDateUTC(paymentDate);
					this.schedulePaymentUpsert_amount = [amount];
					this.schedulePaymentUpsert_amount_string = [amount.replace(".", ",")];
					this.schedulePaymentUpsert_valor = amount;
					this.schedulePaymentUpsert_valor_string = amount.replace(".", ",");
				}
				this.invalidForm = true;
				this.$refs.modalSchedule.show();
			},

			async save () {
				const payload = {
					comments: this.schedulePaymentUpsert_comments,
					reference: this.schedulePaymentUpsert_reference,
					idCategoryExpense: this.schedulePaymentUpsert_idCategoryExpense,
					idAccountSchedule: this.schedulePaymentUpsert_idAccountSchedule,
					date: this.schedulePaymentUpsert_date,
					amount: this.schedulePaymentUpsert_amount,
					paymentDate: this.schedulePaymentUpsert_paymentDate,
					installments: this.schedulePaymentUpsert_installments,
					valor: this.schedulePaymentUpsert_valor,
					automaticPay: this.permitePagamento,
					idPaymentSchedule: this.schedulePaymentUpsert_idPaymentSchedule
				};

				const validateParcelas = this.validateAmount(payload);

				if (!payload.idCategoryExpense ||
					!payload.idAccountSchedule ||
					!payload.amount.length ||
					!payload.installments || !validateParcelas)
				{
					const messagem = "Verifique se os seguintes campos estão preenchidos corretamente: ";
					let messagem_1 = !payload.idCategoryExpense ? "- Categoria da Transação. " : "";
					messagem_1 = messagem_1.concat(!payload.idAccountSchedule ? "- Conta. " : "");
					messagem_1 = messagem_1.concat(!payload.amount.length || !payload.installments ? "- Parcela a agendar. " : "");
					messagem_1 = messagem_1.concat(!validateParcelas ? "- Valor das parcelas. " : "");

					await this.$swal({
						title: "Salvar",
						text: messagem.concat(messagem_1),
						type: "error",
						showCancelButton: false,
						//imageWidth: 50,
						//imageHeight: 50,
						confirmButtonColor: "rgb(132, 170, 194)",
						confirmButtonText: "Ok"
					});
					this.invalidForm = true;
					return;
				}

				this.$snotify.async("Aguarde...", "Salvando", async () => {
					try {
						if (payload.idPaymentSchedule)
							await this.paymentScheduleService.updatePaymentSchedule({payload});
						else
							await this.paymentScheduleService.createPaymentSchedule({payload});

						this.updatePayOfDayEdit(payload);
						this.fecharModal("modalSchedule");
						this.limparModal();
						// this.findAccount();
						// this.itemSelected = false;

						return {
							title: "Sucesso!",
							body: "Agendamento salvo com sucesso.",
							config: this.config
						};
					} catch (error) {
						const msgError = error.response.data.message || "Erro ao tentar salvar o agendamento!";
						throw {
							title: "Falha!",
							body: msgError,
							config
						};
					}
				});
			},

			validateAccountClass (idAccount) {
				if (this.editing) return;

				const accountSelected = this.optionModalAccount.filter(a => a.value === idAccount);

				if (accountSelected.length) {
					this.permiteParcelas = accountSelected[0].permiteParcelas;
					this.permitePagamento = accountSelected[0].permitePagamento;

					const paymentDate = accountSelected[0].payDay || accountSelected[0].closingDay;
					let month = this.schedulePaymentUpsert_date.getMonth(); // obtem mes do lançamento
					const year = this.schedulePaymentUpsert_date.getFullYear(); // obtem ano do lançamento

					if (accountSelected[0].closingDay && !this.permitePagamento) {
						const accountClosed = this.getStateClosedAccount(
							this.schedulePaymentUpsert_date,
							accountSelected[0].closingDay,
							accountSelected[0].closeNextDayWeekend
						);
						this.schedulePaymentUpsert_paymentDate = new Date(this.schedulePaymentUpsert_paymentDate.setDate(paymentDate));
						if (accountClosed) {
							this.addMonth(month, year);
							month = this.schedulePaymentUpsert_date.getMonth();
						} else {
							this.schedulePaymentUpsert_paymentDate.setMonth(month);
						}
					} else {
						this.schedulePaymentUpsert_paymentDate = new Date(this.schedulePaymentUpsert_date);
					}
				} else {
					this.permiteParcelas = null;
					this.permitePagamento = null;
					this.schedulePaymentUpsert_paymentDate = new Date(this.schedulePaymentUpsert_date);
				}

				if (!this.permiteParcelas || !this.schedulePaymentUpsert_installments)
					this.schedulePaymentUpsert_installments = 1;

				if (this.permiteParcelas && this.schedulePaymentUpsert_installments > 1)
					this.setInstallments();


				this.schedulePaymentUpsert_amount = [ this.schedulePaymentUpsert_valor ];

			},

			fecharModal (modal) {
				this.$refs[modal].hide();
				this.limparModal();
			},

			async setInstallments (value) {
				if (value) {
					await this.updateValue(value);
				}

				this.schedulePaymentUpsert_valor = this.schedulePaymentUpsert_valor || 0;
				this.schedulePaymentUpsert_installments = this.schedulePaymentUpsert_installments || 0;
				this.schedulePaymentUpsert_amount = [];
				this.schedulePaymentUpsert_amount_string = [];
				let valorParcela = parseFloat((this.schedulePaymentUpsert_valor / this.schedulePaymentUpsert_installments).toFixed(2));
				let somaTotal = 0;
				this.dataPaymentsInstallments = [];

				this.dataPaymentsInstallments.push(dayjs(this.schedulePaymentUpsert_paymentDate).locale("pt-br").format("DD-MM-YYYY"));
				const dateNextPayment = new Date(this.schedulePaymentUpsert_paymentDate);

				for (let parcela = 1 ; parcela <= parseInt(this.schedulePaymentUpsert_installments) ; parcela++) {
					if (parcela === parseInt(this.schedulePaymentUpsert_installments))
						valorParcela = parseFloat((this.schedulePaymentUpsert_valor) - somaTotal).toFixed(2);

					somaTotal = somaTotal + valorParcela;
					this.schedulePaymentUpsert_amount.push(valorParcela);
					this.schedulePaymentUpsert_amount_string.push(numberFormatted(valorParcela).replace(/\./g, ""));

					if (parcela > 1)
						dateNextPayment.setMonth(dateNextPayment.getMonth() + 1);

					this.dataPaymentsInstallments.push(dayjs(dateNextPayment).locale("pt-br").format("DD-MM-YYYY"));
				}

				this.getAccountPayBalance(this.schedulePaymentUpsert_idAccountSchedule);
			},

			async updateValue (value) {
				this.schedulePaymentUpsert_valor_string = await validateNumber(value);
				this.schedulePaymentUpsert_valor = this.schedulePaymentUpsert_valor_string.replace(",", ".");
			},

			validateAmount (payload) {
				if (payload.installments < 2)
					return true;

				const valorTotal = parseFloat(payload.valor);
				const parcelasTotal = parseFloat(parseFloat(payload.amount.reduce((soma, parcela) => parseFloat(soma) + parseFloat(parcela), 0)).toFixed(2));

				if (valorTotal !== parcelasTotal)
					return false;

				return true;
			},

			updatePayOfDayEdit (payload) {
				const editedRecord = this.paymentScheduleOfDay.filter((ele) => ele.idPaymentSchedule === payload.idPaymentSchedule);
				editedRecord[0].amount = payload.valor;
				editedRecord[0].cols[4] = payload.valor;
				this.linhas = this.paymentScheduleOfDay.map(line => line);

				this.findMonth();
				if (!this.linhas.length)
					this.closeModal();
			}
		}
	};
</script>

<template>
  <main
    v-if="possuiPermissao('DASHBOARD_CALENDARIO_V')"
    :style="`width: calc(100% - ${sideBarWidth}px) !important; transform: translateX(${sideBarWidth}px); transition: all 0.6s; margin-top: 1rem`"
	class="dashboard"
  >
	<div>
		<Toast
			:visible="!subscriptionActive"
			:variant="variantToast"
			:onlyWatch="true"
			toast_align="up"
			:message="messageToast"
			:hideOnClick="true"
			:showAfterHide="true"
		/>
		<div
			style="margin-top: 0px"
		>
			<section
				v-if="optionsDashboardViewPermission.length && optionsDashboardViewPermission.length > 1"
				class="section-select-dashboard">
				<b-form-select
					class="select-dashboard"
					v-model="dashboardView"
					name="dashboard-view"
					:options="optionsDashboardViewPermission"
					@input="changeDashboardView"
					:disabled="loading"
				/>
			</section>
			<TotalsMonth
				v-if="dashboardView === 1"
				style="margin-top: 14px"
				:options="totalesMes"
				:show="true"
				:showHideButton="false"
				:showAccountBalance="possuiPermissao('REL_V_CONTAS')"
				@click="handleShowTotales"
				:isLoading="isLoading"
				:date="dateSearch"
			/>
			<ChartMedia
				v-if="dashboardView === 2"
				:title="`Análise Comparativa - ${optionsTitleMes.label}`"
				type="pie"
				:searchDate="dateSearch"
				:showXAxisLabel="false"
				:showZoom="false"
				:chartKey="dashboardView"
				:forceUpdate="forceChartUpdate"
			/>
			<ChartMedia
				v-if="dashboardView === 3"
				:title="`Despesas por categorias - ${optionsTitleMes.label}`"
				:searchDate="dateSearch"
				:showXAxisLabel="true"
				:showZoom="true"
				:chartKey="dashboardView"
				:showLegend="false"
				:forceUpdate="forceChartUpdate"
			/>
			<ChartMedia
				v-if="dashboardView === 4"
				:title="`Receitas por categorias - ${optionsTitleMes.label}`"
				:searchDate="dateSearch"
				:showXAxisLabel="true"
				:showZoom="true"
				:chartKey="dashboardView"
				:showLegend="false"
				:forceUpdate="forceChartUpdate"
			/>
			<section class="title-month">
				<button
					class="button-arrow"
					@click="findMonth(-1)"
				>
					<ChevronsLeftIcon class="icons-chevrons" color="var(--cal-header-color)"/>
				</button>
				<TitleMonth
					:options="{
						label: optionsTitleMes.label || null,
						backColor: optionsTitleMes.backColor || null,
						color: optionsTitleMes.color || null,
					}"
					style="width: 90% !important;"
				/>
				<button
					class="button-arrow"
					@click="findMonth(1)"
				>
					<ChevronsRightIcon class="icons-chevrons" color="var(--cal-header-color)"/>
				</button>
			</section>
			<section class="calendar">
				<div
				v-for="diaSem in diasSemana"
				:key="diaSem.ordem"
				>
				<LabelDay
					:options="{
					label: diaSem.label,
					backColor: diaSem.backColor,
					color: diaSem.color,
					}"
				/>
				</div>
				<div v-for="item in calendario" :key="item.day">
				<ShowDay
					:options="{
					day: item.day,
					value: item.value,
					show: item.show,
					color: item.color,
					backColor: item.backColor,
					colorTextDay: item.colorTextDay,
					today: item.today,
					}"
					@clickShowDay="setDayPaymentSchedule(item)"
				/>
				</div>
				<div
					v-if="isLoading"
					style="position: fixed; height: 50vh; width: 100%; display: flex; align-items: center; justify-content: center;">
					<div class="spinner-border" role="status">
						<span class="sr-only">Carregando...</span>
					</div>
				</div>
			</section>
		</div>
		<b-modal
			ref="modalPaymentsOfDay"
			hide-footer
			:hide-header="hideHeaderModal"
			:title="titleModalPaymentsOfDay"
			size="xl"
			no-overflow
			hide-no-focus
		>
			<div v-if="hideHeaderModal" class="title-modal">
				<div class="title-text-modal">{{titleModalPaymentsOfDay}}</div>
				<div
					class="float-right btn-hover-modal"
					@click="closeModal"
				>
					<xIcon/>
				</div>
			</div>
			<div>
				<div class="mt-5 mb-3">
					<div class="filter-wrap">
						<div
							v-if="possuiPermissao('GER_P_AGENDAMENTOS')"
							class="filter mr-2 icon-payment"
							v-bind:class="!statusModalPayment ? 'filter-disable' : ''"
							@click="showPayment('All')"
							title="Baixar Selecionados"
						>
							<CreditCardIcon
								alt="Pagamento"
							/>
						</div>
						<div
							v-if="possuiPermissao('GER_D_AGENDAMENTOS')"
							class="filter mr-2 icon-delete"
							v-bind:class="!statusModalPayment? 'filter-disable' : ''"
							@click="confirmaApagar('All')"
							title="Apagar Selecionados"
						>
							<Trash2Icon/>
						</div>
						<div
							class="filter"
							v-bind:class="[showFiltersForm ? 'filter-active' : '']"
							@click="filterDropDownActive"
							title="Alterar Filtros"
						>
							<FilterIcon/>
						</div>
					</div>
					<div v-if="showFiltersForm" class="filters-form content-card responsive-filter-modal">
						<b-row class="filter-rows">
							<b-col>
								<label>
									Contas
								</label>
								<vue-multi-select
									class="multi-100 mb-1 style-select"
									:disabled="isLoading"
									v-model="filterAccount"
									search
									searchPlaceholder="Pesquisar"
									:options="{ multi: true, labelName: 'nome' }"
									:selectOptions="optionsFilterAccount"
									data-cy="account"
									:btnLabel="() => (filterAccount.length > 1 ? `${filterAccount[0].nome}...` : filterAccount[0] && filterAccount[0].nome) || 'Selecione'"
									@input="filterScheduleOfDay"
								/>
							</b-col>
							<b-col>
								<label>
									Categorias
								</label>
								<vue-multi-select
									class="multi-100 mb-1 style-select"
									:disabled="isLoading"
									v-model="filterCategoryExpenses"
									search
									searchPlaceholder="Pesquisar"
									:options="{ multi: true, labelName: 'nome' }"
									:selectOptions="optionsFilterCategoryExpenses"
									data-cy="categoryExpense"
									:btnLabel="() => (filterCategoryExpenses.length > 1 ? `${filterCategoryExpenses[0].nome}...` : filterCategoryExpenses[0] && filterCategoryExpenses[0].nome) || 'Selecione'"
									@input="filterScheduleOfDay"
								/>
							</b-col>
						</b-row>
						<b-row class="filter-rows filter-rows-next">
							<b-col>
								<label>
									Observações
								</label>
								<input
									id="comments"
									class="form-control time-pick"
									v-model="filterComments"
									@input="filterScheduleOfDay"
								/>
							</b-col>
						</b-row>
					</div>
				</div>
				<div v-if="linhas.length" class="table-responsive">
					<DataTable v-if="!isMobile"
						class="data-table"
						:loading="isLoading"
						:colunas="colunas"
						:linhas="linhas"
						:errMsg="errMsg"
						:nosearch="true"
						:nofilters="true"
						name="paymentSchedule"
						:hasPagination="true"
						:noedit="true"
						:acoes="true"
						@clickPayment="showPayment"
						:selectItem="possuiPermissao('GER_M_AGENDAMENTOS')"
						:globalAcoes="false"
						:permissaoPayment="possuiPermissao('DASHBOARD_P_AGENDAMENTOS')"
						:permissaoEdit="possuiPermissao('DASHBOARD_U_AGENDAMENTOS')"
						:permissaoDelete="possuiPermissao('DASHBOARD_D_AGENDAMENTOS')"
						:alignColumns="alignColumnsTable"
						@clickCheck="showButonPayment"
						@clickDelete="confirmaApagar"
						@clickEdit="showSchedule"
						scroll
						@clickSortTableBy="(v) => sortTableBy(v)"
						:state="{
							sortBy: sortTable.sortBy,
							sortAsc: sortTable.order === 1,
							query: ''
						}"
						:async="true"
					/>
					<CardTransaction v-else
						:loading="loading"
						:colunas="colunas"
						:linhas="linhas"
						:errMsg="errMsg"
						:acoes="acoes"
						:selectItem="possuiPermissao('GER_M_AGENDAMENTOS')"
						:permissaoPayment="possuiPermissao('DASHBOARD_P_AGENDAMENTOS')"
						:permissaoEdit="possuiPermissao('DASHBOARD_U_AGENDAMENTOS')"
						:permissaoDelete="possuiPermissao('DASHBOARD_D_AGENDAMENTOS')"
						@clickCheck="showButonPayment"
						@clickDelete="confirmaApagar"
						@clickEdit="showSchedule"
						@clickPayment="showPayment"
						:globalAcoes="false"
						:colsDataCard="colsDataCard"
					/>
				</div>
				<div v-else class="alert alert-warning" role="alert">
					{{ errMsg }}
				</div>
			</div>
		</b-modal>
		<b-modal
			ref="modalPaymentExecute"
			hide-footer
			:title="`Baixa de ${titleModalPaymentsOfDay}`"
			size="lg"
			no-overflow
			hide-no-focus
		>
			<div>
				<div class="modal-edicao mb-3 modal-edicao-label">
					<b-row >
						<b-col cols="8" class="text-parcela">
							<label class="label-parcela">Data Pagamento</label>
							<div class="d-flex">
								<datepicker
									class="w-100"
									placeholder=""
									id="paymentExecuteDate"
									input-class="bg-white"
									v-model="paymentExecuteDate"
									:format="formatter"
									:language="ptBR"
									:bootstrap-styling="true"
									data-cy="paymentExecuteDate"
								/>
							</div>
						</b-col>

						<b-col cols="4" class="d-block text-parcela">
							<span class="label-parcela">Valor</span>
							<b-form-input
								name="paymentExecuteAmount"
								class="modal-edicao-input number-class"
								v-model="paymentExecuteAmount"
								:disabled="true"
							/>
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="8" class="label-parcela">
							<span>
								Conta
							</span>
							<b-form-select
								class="modal-edicao-input"
								v-model="paymentExecuteAccount"
								name="paymentExecuteAccount"
								:options="optionModalAccount.filter(account => account.permitePagamento)"
								:disabled="isLoading"
								v-validate="{ required: !havePermissionMultiAccountPay }"
								:state="validateState('paymentExecuteAccount')"
								@change="getAccountPayBalance(paymentExecuteAccount)"
								inputmode="numeric"
							>
								<template slot="first">
									<option :value="null">
										Selecione
									</option>
								</template>
							</b-form-select>
						</b-col>

						<b-col cols="4" class="d-flex">
							<section class="d-block text-parcela" style="width: 100%;">
								<span class="label-parcela">Balance</span>
								<b-form-input
									name="accountPayBalance"
									class="modal-edicao-input number-class"
									v-model="accountPayBalance"
									:disabled="true"
								/>
							</section>
							<section
								v-if="havePermissionMultiAccountPay"
								class="section-btn-add-pay"
							>
								<b-button
									class="btn-pay green"
									@click="addMethotdPay(paymentExecuteAccount)"
									:disabled="insufficientBalanceAccount || balanceZero || !paymentExecuteAccount"
								>
									+
								</b-button>
							</section>
						</b-col>
					</b-row>
					<section
						v-if="methods_pay.length"
						class="table-parcelas mt-2"
					>
						<b-row
							v-for="i in methods_pay.length" :key="i"
							class="mt-1 mb-1 mr-0 justify-items"
						>
							<b-col
								cols="2" class="d-flex label-parcela"
							>
								<span class="label-parcela"># {{ i }}</span>
							</b-col>
							<b-col
								cols="5" class="d-flex label-parcela"
							>
								<span class="label-parcela">{{ methods_pay[i - 1].account.description }}</span>
							</b-col>
							<b-col
								cols="5" class="d-flex label-parcela"
							>
								<b-form-input
									:name="`methods_pay-${i}-amount`"
									class="modal-edicao-input number-class"
									v-model="methods_pay[i - 1].amount_string"
									autocomplete="off"
									placeholder=""
									inputmode="numeric"
									:state="validateState(`methods_pay-${i}-amount`)"
									v-validate="'required|noZeroCurrency'"
									@input="setNewValueMethodPay(methods_pay[i - 1].amount_string, i - 1)"
								/>
								<div
									class="btn-pay red"
									title="Apagar"
									@click="delMethodPay(i - 1)"
								>
									<Trash2Icon size="24" alt="Apagar"/>
								</div>
							</b-col>
						</b-row>
					</section>
					<b-row class="mt-2">
						<b-col cols="12" class="d-block text-parcela">
							<span class="label-conta">Observações</span>
							<b-form-input
								name="paymentExecuteComments"
								class="modal-edicao-input"
								v-model="paymentExecuteComments"
								autocomplete="off"
								placeholder=""
								v-validate="{ required: false }"
								:state="validateState('paymentExecuteComments')"
							/>
						</b-col>
					</b-row>
				</div>
				<b-button class="ml-1 mt-3 float-left btn-modal btn-cancel" @click="previousModal('modalPayment')">Anterior</b-button>
				<b-button class="mt-3 float-left btn-modal btn-limpar" @click="limparModal()">Limpar</b-button>
				<b-button
					class="mt-3 ml-1 float-right btn-modal btn-salvar"
					@click="paymentExecute"
					:disabled="invalidForm || invalidFormMulti"
				>
					Salvar
				</b-button>
			</div>
		</b-modal>
		<b-modal
			ref="modalSchedule"
			hide-footer
			:title="titleModalSchedule"
			size="md"
			no-overflow
			hide-no-focus="true"
		>
			<div class="modal-edicao modal-edicao-label">
				<b-row class="filters-orientation">
					<b-col class="mb-3 text-parcela">
						<label class="label-parcela">Data Lançamento</label>
						<div class="d-flex">
							<datepicker
								class="w-100"
								placeholder=""
								id="data-lancamento"
								input-class="bg-white"
								v-model="schedulePaymentUpsert_date"
								:format="formatter"
								:language="ptBR"
								:bootstrap-styling="true"
								data-cy="Data Lançamento"
								@input="validateAccountClass(schedulePaymentUpsert_idAccountSchedule)"
								:disabled="notAdvancedEdit"
							/>
						</div>
					</b-col>
					<b-col class="text-parcela">
						<label class="label-parcela">Data Pagamento</label>
						<div class="d-flex">
							<datepicker
								class="w-100"
								placeholder=""
								id="data-pagamento"
								input-class="bg-white"
								v-model="schedulePaymentUpsert_paymentDate"
								:format="formatter"
								:language="ptBR"
								:bootstrap-styling="true"
								data-cy="Data Pagamento"
								:disabled="notAdvancedEdit"
							/>
						</div>
					</b-col>
				</b-row>
				<b-row class="mt-2">
					<b-col cols="6" class="text-parcela">
						<span class="label-parcela">
							Conta
						</span>
						<b-form-select
							class="modal-edicao-input"
							v-model="schedulePaymentUpsert_idAccountSchedule"
							name="accountSchedule"
							:options="optionModalAccount.filter(ele => !ele.permitePagamento)"
							:disabled="notAdvancedEdit"
							v-validate="{ required: true }"
							:state="validateState('accountSchedule')"
							@input="validateAccountClass(schedulePaymentUpsert_idAccountSchedule)"
							@change="getAccountPayBalance(schedulePaymentUpsert_idAccountSchedule)"
						>
							<template slot="first">
								<option :value="null">
									Selecione
								</option>
							</template>
						</b-form-select>
					</b-col>
					<b-col cols="6" class="text-parcela">
						<span  class="label-parcela">
							Categoria da Transação
						</span>
						<b-form-select
							class="modal-edicao-input"
							v-model="schedulePaymentUpsert_idCategoryExpense"
							name="categoryExpense"
							:options="optionModalCategoryExpense"
							:disabled="notAdvancedEdit"
							v-validate="{ required: true }"
							:state="validateState('categoryExpense')"
						>
							<template slot="first">
								<option :value="null">
									Selecione
								</option>
							</template>
						</b-form-select>
					</b-col>
				</b-row>
				<b-row class="mt-3">
					<b-col cols="6" class="d-block text-parcela">
						<span class="label-parcela">Valor</span>
						<b-form-input
							name="valor"
							class="modal-edicao-input number-class"
							v-model="schedulePaymentUpsert_valor_string"
							autocomplete="off"
							placeholder=""
							v-validate="'required|noZeroCurrency'"
							:state="validateState('valor')"
							@input="setInstallments(schedulePaymentUpsert_valor_string)"
							inputmode="numeric"
						/>
					</b-col>
					<b-col v-if="!permiteParcelas || editing" cols="6" class="d-block text-parcela">
						<span class="label-parcela">Balance</span>
						<b-form-input
							name="accountPayBalance"
							class="modal-edicao-input number-class"
							v-model="accountPayBalance"
							inputmode="numeric"
							:disabled="true"
						/>
					</b-col>
					<b-col v-else cols="6" class="d-block text-parcela">
						<span class="label-parcela">Parcelas</span>
						<b-form-input
							name="installments"
							class="modal-edicao-input number-class"
							type="number"
							v-model="schedulePaymentUpsert_installments"
							autocomplete="off"
							placeholder=""
							min="1"
							v-validate="{ required: true, min_value: 1 }"
							:state="validateState('installments')"
							@input="setInstallments()"
							:disabled="!permiteParcelas || editing"
						/>
					</b-col>
				</b-row>
				<section
					v-if="parseInt(schedulePaymentUpsert_installments) > 1"
					class="label-table mt-3"
				>
					<div class="mt-2" style="padding: 3px 0;">
						<b-row
							class="ml-4 mr-5"
						>
							<b-col
								cols="3"
							>
								Parcela
							</b-col>
							<b-col
								cols="4"
							>
								Vencimento
							</b-col>
							<b-col
								cols="5"
							>
								Valor
							</b-col>
						</b-row>
					</div>
				</section>
				<section
					v-if="parseInt(schedulePaymentUpsert_installments) > 1"
					class="table-parcelas"
				>
					<b-row
						v-for="i in parseInt(schedulePaymentUpsert_installments)" :key="i"
						class="ml-2 mb-1 mr-5 justify-items"
					>
						<b-col
							cols="3" class="d-flex label-parcela"
						>
							<span class="label-parcela"># {{ i }}</span>
						</b-col>
						<b-col
							cols="4" class="d-flex label-parcela"
						>
							<span class="label-parcela">{{ dataPaymentsInstallments[i] }}</span>
						</b-col>
						<b-col
							cols="5" class="d-flex label-parcela"
						>
							<b-form-input
								:name="`amount-${i}`"
								class="modal-edicao-input number-class"
								v-model="schedulePaymentUpsert_amount_string[i - 1]"
								autocomplete="off"
								placeholder=""
								:state="validateState(`amount-${i}`)"
								v-validate="'required|noZeroCurrency'"
								@input="setNewValue(schedulePaymentUpsert_amount_string[i - 1], i - 1)"
								inputmode="numeric"
							/>
						</b-col>
					</b-row>
				</section>
				<b-row class="mt-3 filters-orientation">
					<b-col class="d-block text-parcela mb-3">
						<span class="label-parcela">Referência</span>
						<b-form-input
							name="reference"
							class="modal-edicao-input"
							v-model="schedulePaymentUpsert_reference"
							autocomplete="off"
							placeholder=""
							v-validate="{ required: false }"
							:state="validateState('reference')"
						/>
					</b-col>

					<b-col class="d-block text-parcela">
						<span class="label-parcela">Observações</span>
						<b-form-input
							name="comments"
							class="modal-edicao-input"
							v-model="schedulePaymentUpsert_comments"
							autocomplete="off"
							placeholder=""
							v-validate="{ required: false }"
							:state="validateState('comments')"
						/>
					</b-col>
				</b-row>
			</div>
			<b-button class="ml-1 mt-3 float-left btn-modal btn-cancel" @click="fecharModal('modalSchedule')">Cancelar</b-button>
			<b-button class="mt-3 float-left btn-modal btn-limpar" @click="limparModal()">Limpar</b-button>
			<b-button
				class="mt-3 ml-1 float-right btn-modal btn-salvar"
				@click="save"
				:disabled="invalidForm"
			>
				Salvar
			</b-button>
		</b-modal>
	</div>
  </main>
</template>

<style scoped>
.calendar {
  display: grid;
  padding: 0px;
  width: 100%;
  grid-template-columns: repeat(7, 1fr);
  background: azure;
}

.title-month {
  background: azure;
  display: flex;
  width: 100%;
  margin-top: 14px;
}

.button-arrow {
  display: flex;
  width: 5%;
  background: var(--color-button-grey);
  border-style: outset;
  border-color: var(--color-button-grey-hover);
  border-width: 1px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.button-arrow:hover {
  background: var(--color-button-grey-hover);
}

.button-arrow:focus {
  background: var(--color-green-light);
}

.modal-edicao-input {
	font-family: 'Roboto Condensed Regular';
	font-size: 1rem;
}

.number-class {
	text-align: right;
}

.label-parcela {
  margin-bottom: 0px;
  color: #696969 !important;
  width: 10rem;
}

.text-parcela {
  font-family: 'Roboto Condensed Regular';
  font-size: 16px;
}

.btn-salvar-top {
  width: 11.25rem;
  white-space: noWrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 8px;
}

.table-margin-top {
  margin-top: calc(50px + 2rem);
}

.responsive-filter-modal {
	display: flex;
	justify-content: space-evenly;
}
.filter-rows {
	width: 50%;
	padding: 1.25rem 0 1.25rem 1.25rem;
	font-size: 1.15rem;
}
.filter-rows-next {
	padding-left: 0.8rem;
	padding-right: 1.25rem;
}

.icon-payment {
	display: flex;
	justify-content: center;
	background-color: green;
	color: white;
	padding: 2px;
}

.icon-delete {
	display: flex;
	justify-content: center;
	background-color: red;
	color: white;
	padding: 2px;
}


.label-parcela {
	margin-bottom: 0px;
	color: #696969 !important;
	width: 10rem;
}

.text-parcela {
	font-family: 'Roboto Condensed Regular';
	font-size: 1rem;
}

.table-parcelas {
	padding-left: 2rem;
	margin-bottom: 12px;
	max-height: 25vh;
	overflow-y: scroll;
	overflow-x: hidden;
	border: solid 1px #e2e2e2;
	background-color: #f7f5f5;
	border-top: none;
}

.justify-items {
	align-items: center;
}

.label-table {
	background-color: #f0efef;
	font-family: 'Roboto Condensed Regular';
	color: #696969 !important;
	border: solid 1px #e2e2e2;
	border-bottom: none;
	height: 3rem;
}

.modal-edicao-input {
	font-family: 'Roboto Condensed Regular';
	font-size: 1rem;
}

.dashboard {
	padding-left: 8px;
	padding-right: 8px;
}

.select-dashboard {
	min-width: 130px;
	max-width: 200px;
	width: 20%;
	font-family: "Roboto Condensed Regular";
	font-size: 0.875rem;
}

.section-select-dashboard {
	width: 100%;
	display: flex;
	justify-content: right;
}

@media (max-width: 1576px) {
  .button-arrow {
    width: 6%
  }
}

@media (max-width: 1024px) {
  .button-arrow {
    width: 9%
  }
}

@media (max-width: 768px) {
	.responsive-filter-modal {
		display: block;
	}
	.filter-rows {
		padding-left: 1.8rem;
		padding-right: 0;
		width: 100%;
	}
	.filter-rows-next {
		padding-top: 0;
		margin-top: -1rem;
	}
}

@media (max-width: 600px) {
  .button-arrow {
    width: 11%
  }
}
</style>