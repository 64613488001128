// List of icons: https://vue-feather-icons.egoist.sh/
import {
	FilterIcon,
	RadioIcon,
	PaperclipIcon,
	TypeIcon,
	CopyIcon,
	ChevronDownIcon,
	ChevronUpIcon,
	InfoIcon,
	ClipboardIcon,
	AlertTriangleIcon,
	AlertCircleIcon,
	MonitorIcon,
	BellIcon,
	SettingsIcon,
	MenuIcon,
	UserIcon,
	ArrowLeftIcon,
	Link2Icon,
	ChevronRightIcon,
	TagIcon,
	LayersIcon,
	GitPullRequestIcon,
	ChevronsRightIcon,
	ChevronsLeftIcon,
	BarChart2Icon,
	CheckIcon,
	FileTextIcon,
	DownloadIcon,
	XCircleIcon,
	CheckCircleIcon,
	LogInIcon,
	LogOutIcon,
	XIcon,
	Trash2Icon,
	TrashIcon,
	CheckSquareIcon,
	CommandIcon,
	PrinterIcon,
	AlertOctagonIcon,
	LockIcon,
	Edit3Icon,
	MoreHorizontalIcon,
	GridIcon,
	CpuIcon,
	EditIcon,
	CalendarIcon,
	SearchIcon,
	SlidersIcon,
	MapIcon,
	RepeatIcon,
	MapPinIcon,
	EyeIcon,
	EyeOffIcon,
	ArchiveIcon,
	PlusCircleIcon,
	FilePlusIcon,
	SunIcon,
	SaveIcon,
	ClockIcon,
	MaximizeIcon,
	MinimizeIcon,
	ArrowLeftCircleIcon,
	ArrowRightCircleIcon,
	HomeIcon,
	Minimize2Icon,
	Maximize2Icon,
	Volume2Icon,
	VolumeXIcon,
	ZoomInIcon,
	ZoomOutIcon,
	ImageIcon,
	ZapIcon,
	ZapOffIcon,
	ExternalLinkIcon,
	UserXIcon,
	ActivityIcon,
	CircleIcon,
	UsersIcon,
	PackageIcon,
	BoxIcon,
	SunsetIcon,
	FolderIcon,
	FileIcon,
	FolderMinusIcon,
	FolderPlusIcon,
	ArrowDownLeftIcon,
	ArrowUpRightIcon,
	CreditCardIcon,
	RefreshCwIcon,
	ListIcon,
	AwardIcon,
	DollarSignIcon,
	MehIcon,
	UserCheckIcon,
	StarIcon,
	BriefcaseIcon,
	MessageSquareIcon
} from "vue-feather-icons";

function camelToKebab (name) {
	return name.substr(0, name.length - 4).replace(/([A-Z])/g, "-$1").toLowerCase();
}

function genIcon (name, children, flip = false) {
	return {
		name,
		props: {
			size: {
				type: String,
				default: "24",
				validator: function validator (s) {
					return !isNaN(s) || s.length >= 2 && !isNaN(s.slice(0, s.length - 1)) && s.slice(-1) === "x";
				}
			}
		},
		functional: true,
		render: function render (h, ctx) {
			const size = ctx.props.size.slice(-1) === "x" ? ctx.props.size.slice(0, ctx.props.size.length - 1) + "em" : parseInt(ctx.props.size) + "px";
			const attrs = ctx.data.attrs || {};
			attrs.width = attrs.width || size;
			attrs.height = attrs.height || size;
			ctx.data.attrs = attrs;
			return h("svg", {
				attrs: {
					xmlns: "http://www.w3.org/2000/svg",
					width: "24",
					height: "24",
					viewBox: "0 0 24 24",
					fill: flip ? "currentColor" : "none",
					stroke: flip ? "none" : "currentColor",
					"stroke-width": "2",
					"stroke-linecap": "round",
					"stroke-linejoin": "round"
				},
				class: `feather feather${camelToKebab(name)}`,
				...ctx.data
			}, children(h));
		}
	};
}

const DashboardIcon = genIcon("DashboardIcon", h => [h("path", {
	attrs: {
		d: "M12.984 3h8.016v6h-8.016v-6zM12.984 21v-9.984h8.016v9.984h-8.016zM3 21v-6h8.016v6h-8.016zM3 12.984v-9.984h8.016v9.984h-8.016z"

	}
})], true);

const LineChartIcon = genIcon("LineChartIcon", h => [h("path", {
	attrs: {
		d: "M22 11h-4c-0.4 0-0.8 0.3-0.9 0.7l-2.1 6.1-5.1-15.1c-0.1-0.4-0.5-0.7-0.9-0.7s-0.8 0.3-0.9 0.7l-2.8 8.3h-3.3c-0.6 0-1 0.4-1 1s0.4 1 1 1h4c0.4 0 0.8-0.3 0.9-0.7l2.1-6.1 5.1 15.2c0.1 0.4 0.5 0.7 0.9 0.7s0.8-0.3 0.9-0.7l2.8-8.3h3.3c0.6 0 1-0.4 1-1s-0.4-1.1-1-1.1z",
		fill: "currentColor"
	}
})]);

const BlurLinearIcon = genIcon("BlurLinearIcon", h => [h("path", {
	attrs: {
		d: "M12.984 17.016c-0.563 0-0.984-0.469-0.984-1.031s0.422-0.984 0.984-0.984 1.031 0.422 1.031 0.984-0.469 1.031-1.031 1.031zM12.984 12.984c-0.563 0-0.984-0.422-0.984-0.984s0.422-0.984 0.984-0.984 1.031 0.422 1.031 0.984-0.469 0.984-1.031 0.984zM12.984 9c-0.563 0-0.984-0.422-0.984-0.984s0.422-1.031 0.984-1.031 1.031 0.469 1.031 1.031-0.469 0.984-1.031 0.984zM17.016 12.516c-0.281 0-0.516-0.234-0.516-0.516s0.234-0.516 0.516-0.516 0.469 0.234 0.469 0.516-0.188 0.516-0.469 0.516zM17.016 8.484c-0.281 0-0.516-0.188-0.516-0.469s0.234-0.516 0.516-0.516 0.469 0.234 0.469 0.516-0.188 0.469-0.469 0.469zM3 3h18v2.016h-18v-2.016zM17.016 16.5c-0.281 0-0.516-0.234-0.516-0.516s0.234-0.469 0.516-0.469 0.469 0.188 0.469 0.469-0.188 0.516-0.469 0.516zM9 17.016c-0.563 0-0.984-0.469-0.984-1.031s0.422-0.984 0.984-0.984 0.984 0.422 0.984 0.984-0.422 1.031-0.984 1.031zM5.016 13.5c-0.844 0-1.5-0.656-1.5-1.5s0.656-1.5 1.5-1.5 1.5 0.656 1.5 1.5-0.656 1.5-1.5 1.5zM5.016 9.516c-0.844 0-1.5-0.656-1.5-1.5s0.656-1.5 1.5-1.5 1.5 0.656 1.5 1.5-0.656 1.5-1.5 1.5zM3 21v-2.016h18v2.016h-18zM9 9c-0.563 0-0.984-0.422-0.984-0.984s0.422-1.031 0.984-1.031 0.984 0.469 0.984 1.031-0.422 0.984-0.984 0.984zM9 12.984c-0.563 0-0.984-0.422-0.984-0.984s0.422-0.984 0.984-0.984 0.984 0.422 0.984 0.984-0.422 0.984-0.984 0.984zM5.016 17.484c-0.844 0-1.5-0.656-1.5-1.5s0.656-1.5 1.5-1.5 1.5 0.656 1.5 1.5-0.656 1.5-1.5 1.5z",
		fill: "currentColor"
	}
})]);

const OCheckNetworkIcon = genIcon("OCheckNetworkIcon", h => [h("path", {
	attrs: {
		d: "M14.25 18.6667C14.25 18.3793 14.1358 18.1038 13.9327 17.9006C13.7295 17.6975 13.4539 17.5833 13.1666 17.5833H12.0833V15.4167H16.4166C16.9913 15.4167 17.5424 15.1884 17.9487 14.7821C18.355 14.3757 18.5833 13.8246 18.5833 13.25V2.41667C18.5833 1.84203 18.355 1.29093 17.9487 0.884602C17.5424 0.478273 16.9913 0.25 16.4166 0.25H5.58329C5.00866 0.25 4.45756 0.478273 4.05123 0.884602C3.6449 1.29093 3.41663 1.84203 3.41663 2.41667V13.25C3.41663 13.8246 3.6449 14.3757 4.05123 14.7821C4.45756 15.1884 5.00866 15.4167 5.58329 15.4167H9.91663V17.5833H8.83329C8.54597 17.5833 8.27042 17.6975 8.06726 17.9006C7.8641 18.1038 7.74996 18.3793 7.74996 18.6667H0.166626V20.8333H7.74996C7.74996 21.1206 7.8641 21.3962 8.06726 21.5994C8.27042 21.8025 8.54597 21.9167 8.83329 21.9167H13.1666C13.4539 21.9167 13.7295 21.8025 13.9327 21.5994C14.1358 21.3962 14.25 21.1206 14.25 20.8333H21.8333V18.6667H14.25ZM5.58329 13.25V2.41667H16.4166V13.25H5.58329ZM6.66663 8.23417L8.00996 6.89083L9.84079 8.7325L13.99 4.58333L15.3333 6.20833L9.84079 11.7008L6.66663 8.23417Z",
		fill: "currentColor"
	}
})]);

const AppsIcon = genIcon("AppsIcon", h => [h("path", {
	attrs: {
		d: "M15.984 20.016v-4.031h4.031v4.031h-4.031zM15.984 14.016v-4.031h4.031v4.031h-4.031zM9.984 8.016v-4.031h4.031v4.031h-4.031zM15.984 3.984h4.031v4.031h-4.031v-4.031zM9.984 14.016v-4.031h4.031v4.031h-4.031zM3.984 14.016v-4.031h4.031v4.031h-4.031zM3.984 20.016v-4.031h4.031v4.031h-4.031zM9.984 20.016v-4.031h4.031v4.031h-4.031zM3.984 8.016v-4.031h4.031v4.031h-4.031z",
		fill: "currentColor"
	}
})]);

const MoneyIcon = genIcon("MoneyIcon", h => [h("path", {
	attrs: {
		d: "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6zm0-10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z",
		fill: "currentColor"
	}
})]);

const EditCustomIcon = genIcon("EditCustomIcon", h => [h("path", {
	attrs: {
		d: "M11,11H0V3A3,3,0,0,1,3,0h8ZM2,9H9V2H3A1,1,0,0,0,2,3Z M11,24H3a3,3,0,0,1-3-3V13H11ZM2,15v6a1,1,0,0,0,1,1H9V15Z M24,11H13V0h8a3,3,0,0,1,3,3ZM15,9h7V3a1,1,0,0,0-1-1H15Z M18.5 22l-6-6h12l-6 6z",
		fill: "currentColor"
	}
})]);

const TransactionsCustomIcon = genIcon("TransactionsCustomIcon", h => [h("path", {
	attrs: {
		d: "M24,7v1c0,.552-.447,1-1,1s-1-.448-1-1v-1c0-1.654-1.346-3-3-3H5c-1.654,0-3,1.346-3,3v10c0,1.654,1.346,3,3,3h3c.553,0,1,.448,1,1s-.447,1-1,1h-3c-2.757,0-5-2.243-5-5V7C0,4.243,2.243,2,5,2h14c2.757,0,5,2.243,5,5Zm-9,2h4c.553,0,1-.448,1-1s-.447-1-1-1h-4c-.553,0-1,.448-1,1s.447,1,1,1Zm8.121,2.879c.566,.566,.879,1.32,.879,2.121s-.313,1.555-.879,2.122l-6.707,6.707c-.755,.755-1.76,1.172-2.828,1.172h-1.586c-.553,0-1-.448-1-1v-1.586c0-1.068,.416-2.073,1.172-2.828l6.707-6.707c1.17-1.17,3.072-1.17,4.242,0Zm-1.121,2.121c0-.267-.104-.518-.293-.707-.391-.391-1.023-.39-1.414,0l-6.707,6.707c-.372,.373-.586,.888-.586,1.414v.586h.586c.534,0,1.036-.208,1.414-.586l6.707-6.707c.189-.189,.293-.44,.293-.707Zm-13,1h-2.268c-.356,0-.688-.192-.867-.5-.275-.479-.886-.644-1.366-.365-.478,.277-.642,.888-.364,1.366,.534,.925,1.53,1.499,2.598,1.499h.268c0,.552,.447,1,1,1s1-.448,1-1c1.654,0,3-1.346,3-3,0-1.36-.974-2.51-2.315-2.733l-3.041-.507c-.373-.062-.644-.382-.644-.76,0-.551,.448-1,1-1h2.268c.356,0,.688,.192,.867,.5,.275,.478,.885,.642,1.366,.365,.478-.277,.642-.888,.364-1.366-.534-.925-1.53-1.5-2.598-1.5h-.268c0-.552-.447-1-1-1s-1,.448-1,1c-1.654,0-3,1.346-3,3,0,1.36,.974,2.51,2.315,2.733l3.041,.507c.373,.062,.644,.382,.644,.76,0,.551-.448,1-1,1Zm5-3c0,.552,.448,1,1,1s1-.448,1-1-.448-1-1-1-1,.448-1,1Z",
		fill: "currentColor"
	}
})]);

const ClientesCustomIcon = genIcon("ClientesCustomIcon", h => [h("path", {
	attrs: {
		d: "m11,2c0-1.105.895-2,2-2s2,.895,2,2-.895,2-2,2-2-.895-2-2Zm.942,3c.651.636,1.058,1.52,1.058,2.5v.5h2.407c.37,0,.665-.344.578-.704-.317-1.317-1.503-2.296-2.917-2.296h-1.126Zm-3.942,0c1.105,0,2-.895,2-2s-.895-2-2-2-2,.895-2,2,.895,2,2,2ZM.593,8h2.407v-.5c0-.98.407-1.864,1.058-2.5h-1.126C1.518,5,.332,5.979.015,7.296c-.087.36.208.704.578.704Zm22.642,5.015l-6.804,7.637c-1.896,2.128-4.617,3.348-7.467,3.348h-4.965c-2.206,0-4-1.794-4-4v-5c0-2.206,1.794-4,4-4h8.857c1.139,0,2.139.609,2.689,1.519l3.217-3.534c.54-.595,1.281-.944,2.085-.981.812-.039,1.574.24,2.169.782,1.214,1.107,1.312,3.005.218,4.23Zm-1.565-2.752c-.199-.181-.456-.269-.728-.262-.27.013-.519.129-.699.329l-4.427,4.865c-.386,1.07-1.339,1.89-2.514,2.058l-5.161.737c-.548.074-1.054-.302-1.132-.848-.078-.547.302-1.053.849-1.131l5.161-.737c.559-.08.98-.566.98-1.131,0-.63-.513-1.142-1.143-1.142H4c-1.103,0-2,.897-2,2v5c0,1.103.897,2,2,2h4.965c2.279,0,4.456-.976,5.973-2.678l6.805-7.638c.368-.412.335-1.049-.072-1.421Zm-16.063-1.263h4.786c.379,0,.68-.344.591-.704-.324-1.317-1.537-2.296-2.984-2.296s-2.66.979-2.984,2.296c-.089.36.213.704.591.704Zm-2.607-5c1.105,0,2-.895,2-2S4.105,0,3,0,1,.895,1,2s.895,2,2,2Z",
		fill: "currentColor"
	}
})]);

const BankCustomIcon = genIcon("BankCustomIcon", h => [h("path", {
	attrs: {
		d: "m8.293,4.121c-.391-.391-.391-1.023,0-1.414s1.023-.391,1.414,0l1.293,1.293V1c0-.552.448-1,1-1s1,.448,1,1v3l1.293-1.293c.391-.391,1.023-.391,1.414,0s.391,1.023,0,1.414l-2.293,2.293c-.39.39-.902.585-1.414.585s-1.024-.195-1.414-.585l-2.293-2.293Zm2.707,6.879v1c-1.654,0-3,1.346-3,3,0,1.36.974,2.51,2.315,2.733l3.042.507c.373.062.644.382.644.76,0,.551-.448,1-1,1h-2.268c-.356,0-.688-.192-.867-.5-.277-.478-.889-.642-1.366-.365-.479.277-.642.888-.365,1.366.535.925,1.53,1.499,2.598,1.499h.268v1c0,.552.448,1,1,1s1-.448,1-1v-1c1.654,0,3-1.346,3-3,0-1.36-.974-2.51-2.315-2.733l-3.041-.507c-.373-.062-.644-.382-.644-.76,0-.551.449-1,1-1h2.268c.356,0,.688.192.867.5.274.479.886.641,1.366.365.478-.277.642-.888.364-1.366-.534-.925-1.53-1.5-2.598-1.5h-.268v-1c0-.552-.448-1-1-1s-1,.448-1,1Zm12,11h-2v-12h.292c1.023,0,1.95-.555,2.417-1.448.438-.837.381-1.797-.153-2.566-.448-.645-1.024-1.163-1.668-1.498l-2.641-1.375c-.492-.257-1.095-.065-1.349.425-.255.49-.065,1.094.425,1.349l2.641,1.376c.357.186.686.485.949.864.107.155.115.323.023.499-.095.181-.313.375-.645.375H2.708c-.332,0-.551-.194-.646-.375-.092-.175-.083-.343.023-.498.265-.38.593-.679.95-.865l2.641-1.375c.49-.255.68-.859.425-1.349-.254-.49-.858-.681-1.349-.425l-2.641,1.375c-.643.335-1.22.853-1.668,1.498-.534.769-.591,1.728-.153,2.565.467.894,1.394,1.448,2.418,1.448h.292v12H1c-.552,0-1,.448-1,1s.448,1,1,1h6c.552,0,1-.448,1-1s-.448-1-1-1h-2v-12h14v12h-2c-.553,0-1,.448-1,1s.447,1,1,1h6c.553,0,1-.448,1-1s-.447-1-1-1Z",
		fill: "currentColor"
	}
})]);


/**
 * @param {import("vue").VueConstructor} Vue
 */
export function install (Vue) {
	if (install.installed) return;
	install.installed = true;

	const icons = [
		BellIcon,
		SettingsIcon,
		MenuIcon,
		UserIcon,
		ArrowLeftIcon,
		DashboardIcon,
		Link2Icon,
		ChevronRightIcon,
		TagIcon,
		LayersIcon,
		GitPullRequestIcon,
		ChevronsRightIcon,
		BarChart2Icon,
		CheckIcon,
		XIcon,
		FileTextIcon,
		DownloadIcon,
		XCircleIcon,
		CheckCircleIcon,
		LogInIcon,
		LogOutIcon,
		Trash2Icon,
		TrashIcon,
		CheckSquareIcon,
		CommandIcon,
		PrinterIcon,
		AlertOctagonIcon,
		AlertCircleIcon,
		LockIcon,
		Edit3Icon,
		MoreHorizontalIcon,
		GridIcon,
		CpuIcon,
		EditIcon,
		CalendarIcon,
		LineChartIcon,
		BlurLinearIcon,
		OCheckNetworkIcon,
		AppsIcon,
		SearchIcon,
		SlidersIcon,
		MapIcon,
		RepeatIcon,
		MapPinIcon,
		EyeIcon,
		EyeOffIcon,
		ArchiveIcon,
		PlusCircleIcon,
		FilePlusIcon,
		SunIcon,
		SaveIcon,
		MonitorIcon,
		AlertTriangleIcon,
		ClipboardIcon,
		ClockIcon,
		InfoIcon,
		MaximizeIcon,
		MinimizeIcon,
		Maximize2Icon,
		Minimize2Icon,
		ArrowLeftCircleIcon,
		ArrowRightCircleIcon,
		CopyIcon,
		TypeIcon,
		HomeIcon,
		PaperclipIcon,
		Volume2Icon,
		VolumeXIcon,
		ZoomInIcon,
		ZoomOutIcon,
		ImageIcon,
		ZapIcon,
		ZapOffIcon,
		ExternalLinkIcon,
		ChevronDownIcon,
		ChevronUpIcon,
		FilterIcon,
		RadioIcon,
		UserXIcon,
		CircleIcon,
		ActivityIcon,
		UsersIcon,
		PackageIcon,
		BoxIcon,
		SunsetIcon,
		ChevronsLeftIcon,
		FolderIcon,
		FileIcon,
		FolderMinusIcon,
		FolderPlusIcon,
		ArrowDownLeftIcon,
		ArrowUpRightIcon,
		CreditCardIcon,
		RefreshCwIcon,
		ListIcon,
		AwardIcon,
		DollarSignIcon,
		MoneyIcon,
		MehIcon,
		UserCheckIcon,
		EditCustomIcon,
		TransactionsCustomIcon,
		ClientesCustomIcon,
		BankCustomIcon,
		StarIcon,
		BriefcaseIcon,
		MessageSquareIcon
	];

	for (const icon of icons)
		Vue.component(icon.name, icon);
}

export default { install };
