<template>
	<template-base class="containerCard">
		<div>
			<div class="container-head">
				<div
					v-if="possuiPermissao('GER_I_CONTAS')"
					class="container-add-new"
					v-bind:class="!tiposContas.length ? 'container-add-new-100' : ''"
				>
					<b-button class="add-button" @click="mostrarEdicao({})">
						Adicionar
					</b-button>
				</div>
			</div>
			<div v-if="contas.length" class="head-row mb-2 width-pagination-items">
				<div class="text-head-row width-pagination">
					<label class="text-head-row ml-0">
						Resultado {{ pagination.offset + 1 }}-{{ pagination.offset + contas.length }} de {{ pagination.count }}
					</label>
					<b-pagination
						class="mr-0 mb-2"
						id="page"
						v-model="currentPage"
						:total-rows="pagination.count"
						:per-page="pagination.limit.value"
					></b-pagination>
				</div>
				<b-form
					class="text-head-row width-pagination">
					<label class="text-head-row ml-0">
						Itens por página
					</label>
					<b-form-select
						class="mr-0 mb-2"
						v-model="pagination.limit.value"
						name="itens-por-pagina"
						:options="optionsItensPorPag"
						@input="changeItensByPage()"
						:disabled="loading"
					/>
				</b-form>
			</div>
			<b-modal
				ref="modalEdicao"
				hide-footer
				:title="titleModalEdicao"
				size="md"
			>
				<div class="modal-edicao mb-3 modal-edicao-label">
					<b-row class="mb-3">
						<b-col class="d-block text-parcela">
							<span class="label-conta">Conta</span>
							<b-form-input
								name="description-conta"
								class="modal-edicao-input"
								type="text"
								v-model="contaSelecionada.description"
								autocomplete="off"
								placeholder="Digite o nome da conta"
								v-validate="{ required: true }"
								:state="validateState('description-conta')"
							/>
						</b-col>
					</b-row>
					<b-row class="mb-2">
						<b-col v-if="!closingMonthly" class="d-block text-parcela center">
							<span class="label-conta toggle-conta">Mostrar no Dashboard</span>
							<ToggleSwitch
								name="conta-close-monthly"
								class="toggle-conta"
								:value="contaSelecionada.sumBalanceDashboard"
								@toggle="_ => changeToggle('sumBalanceDashboard')"
							/>
						</b-col>
						<b-col class="d-block text-parcela center">
							<span class="label-conta toggle-conta">Inativa</span>
							<ToggleSwitch
								name="conta-close-monthly"
								class="toggle-conta"
								:value="contaSelecionada.inactive"
								@toggle="_ => changeToggle('inactive')"
							/>
						</b-col>
					</b-row>
					<b-row>
						<b-col class="d-block text-parcela mb-2">
							<span class="label-conta">Tipo de Conta</span>
							<b-form-select
								name="id-tipo-conta"
								class="modal-edicao-input mr-1"
								v-model="contaSelecionada.idAccountClass"
								v-validate="{ required: true }"
								:state="validateState('id-tipo-conta')"
								:options="tiposContas"
								:disabled="loading"
								@input="validateTypeAccount(contaSelecionada.idAccountClass)"
							>
								<!-- <template slot="first">
									<option :value="null">
										Nenhum
									</option>
								</template> -->
							</b-form-select>
						</b-col>
					</b-row>
					<b-row class="filters-orientation" v-if="closingMonthly">
						<b-col class="d-block text-parcela mb-2">
							<span class="label-conta">Dia de Fechamento</span>
							<b-form-input
								name="closing-day-conta"
								class="modal-edicao-input"
								type="number"
								min="1"
								max="31"
								v-model="contaSelecionada.closingDay"
								autocomplete="off"
								placeholder="Dia de fechamento"
								v-validate="{ required: true, min_value: 1, max_value: 31 }"
								:state="validateState('closing-day-conta')"
							/>
						</b-col>
						<b-col class="d-block text-parcela mb-2">
							<span class="label-conta">Dia de Pagamento</span>
							<b-form-input
								name="pay-day-conta"
								class="modal-edicao-input"
								type="number"
								min="1"
								max="31"
								v-model="contaSelecionada.payDay"
								autocomplete="off"
								placeholder="Dia de pagamento"
								v-validate="{ required: true, min_value: 1, max_value: 31 }"
								:state="validateState('pay-day-conta')"
							/>
						</b-col>
						<b-col class="d-block text-parcela">
							<span class="label-conta toggle-conta">Fec. Dia Útil Seg.</span>
							<ToggleSwitch
								name="conta-close-monthly"
								class="toggle-conta"
								:value="contaSelecionada.closeNextDayWeekend"
								@toggle="_ => changeToggle('closeNextDayWeekend')"
							/>
						</b-col>
					</b-row>
				</div>
				<b-button class="ml-1 mt-3 float-left btn-modal btn-cancel" @click="fecharModal('modalEdicao')">Cancelar</b-button>
				<b-button class="mt-3 float-left btn-modal btn-limpar" @click="limparModal()">Limpar</b-button>
				<b-button
					class="mt-3 ml-1 float-right btn-modal btn-salvar"
					@click="salvarEdicao"
					:disabled="invalidForm"
				>
					Salvar
				</b-button>
			</b-modal>
		</div>
		<div v-if="contas.length" class="table-responsive">
			<DataTable v-if="!isMobile"
				class="data-table"
				:loading="loading"
				:colunas="colunas"
				:linhas="linhas"
				:errMsg="errMsg"
				:nosearch="true"
				:nofilters="true"
				name="contas"
				:hasPagination="true"
				:noedit="true"
				:acoes="true"
				:permissaoEdit="possuiPermissao('GER_U_CONTAS')"
				:permissaoDelete="possuiPermissao('GER_D_CONTAS')"
				@clickDelete="confirmaApagar"
				@clickEdit="mostrarEdicao"
				@clickSortTableBy="(v) => sortTableBy(v)"
				:state="{
					sortBy: sortTable.sortBy,
					sortAsc: sortTable.order === 1,
					query: ''
				}"
				:async="true"
			/>
			<CardTransaction v-else
				:loading="loading"
				:colunas="colunas"
				:linhas="linhas"
				:errMsg="errMsg"
				:acoes="true"
				:permissaoEdit="possuiPermissao('GER_U_CONTAS')"
				:permissaoDelete="possuiPermissao('GER_D_CONTAS')"
				@clickDelete="confirmaApagar"
				@clickEdit="mostrarEdicao"
				:selectItem="false"
				:globalAcoes="false"
				@clickCheck="false"
				:colsDataCard="colsDataCard"
				:details="false"
				:showSelectItem="false"
			/>
		</div>
		<div v-else class="alert alert-warning" role="alert">
			{{ errMsg }}
		</div>
	</template-base>
</template>

<script>
	import ToggleSwitch from "@/components/ToggleSwitch";

	import DataTable from "@/components/DataTable";
	import TemplateBase from "@/templates/Base";

	import { possuiPermissao } from "../../helpers/permissions";
	import { AccountService } from "../../services/account";
	import { AccountClassService } from "../../services/accountClass";
	import { hasFieldsWithErrors } from "@/helpers/validators";
	import CardTransaction from "@/components/CardTransaction.vue";

	export default {
		components: {
			TemplateBase,
			ToggleSwitch,
			DataTable,
			CardTransaction
		},

		inject: ["parentValidator"],

		data () {
			return {
				contas: [],
				tiposContas: [],
				tiposContasFull: [],
				linhas: [],
				titleModalEdicao: "",
				loading: true,
				contaSelecionada: {
					idAccount: "",
					description: "",
					closingDay: "",
					closeNextDayWeekend: false,
					idAccountClass: null,
					payDay: "",
					sumBalanceDashboard: false,
					inactive: false
				},

				invalidForm: true,
				closingMonthly: false,
				accountService: new AccountService(),
				accountClassService: new AccountClassService(),

				errMsg: "Nenhuma conta cadastrada!",
				colunas: [
					"Conta",
					"Fecha Dia Útil Seg.",
					"Dia Fechamento",
					"Dia Pagamento",
					"Soma Dashboard",
					"Inativa",
					"Tipo de Conta"
				],
				pagination: {
					count: 0,
					page: 1,
					offset: 0,
					limit: {
						value: 25
					},
					pages: 1
				},
				currentPage: 1,
				optionsItensPorPag: [
					{
						value: 5,
						text: "5 Items"
					},
					{
						value: 25,
						text: "25 Items"
					},
					{
						value: 50,
						text: "50 Items"
					},
					{
						value: 75,
						text: "75 Items"
					},
					{
						value: 100,
						text: "100 Items"
					}
				],
				possuiPermissao,
				sortTable: {
					sortBy: 0,
					order: 1
				},
				isMobile: false,
				colsDataCard: {
					0: {
						idx: 0,
						label: "Conta",
						textAlign: "left",
						cols: 6,
						visible: true
					},
					1: {
						idx: 6,
						label: "Tipo de Conta",
						textAlign: "leftr",
						cols: 6,
						visible: true
					},
					2: {
						idx: 1,
						label: "Fecha Dia Útil Seg.",
						textAlign: "center",
						cols: 4,
						visible: true
					},
					3: {
						idx: 2,
						label: "Dia Fechamento",
						textAlign: "center",
						cols: 4,
						visible: true
					},
					4: {
						idx: 3,
						label: "Dia Pagamento",
						textAlign: "center",
						cols: 4,
						visible: true
					},
					5: {
						idx: null,
						label: "",
						textAlign: "",
						cols: 0,
						visible: false
					},
					6: {
						idx: 4,
						label: "Soma Dashboard",
						textAlign: "center",
						cols: 6,
						visible: true
					},
					7: {
						idx: 5,
						label: "Inativa",
						textAlign: "center",
						cols: 6,
						visible: true
					}
				}
			};
		},

		watch: {
			currentPage () {
				this.changePage(this.currentPage);
			}
		},

		created () {
			this.$validator = this.parentValidator;
		},

		async mounted () {
			this.checkIfMobile();
			window.addEventListener("resize", this.checkIfMobile);

			await this.optionsTiposContas();
			await this.findContas();
		},

		beforeDestroy () {
			window.removeEventListener("resize", this.checkIfMobile);
		},

		methods: {
			checkIfMobile	() {
				this.isMobile = window.innerWidth <= 768;
			},

			sortTableBy (v) {
				this.sortTable = v;
				this.findContas();
			},

			changePage () {
				this.pagination.page = this.currentPage;
				this.findContas();
			},

			changeItensByPage () {
				this.pagination.page = 1;
				this.findContas();
			},

			async optionsTiposContas () {
				this.tiposContasFull = (await this.accountClassService.listAccountClass({ offset: 0, limit: null })).rows || [];
				this.tiposContas = this.tiposContasFull.reduce((acc, tp) => [
					...acc,
					{
						value: tp.idAccountClass,
						text: tp.description
					}
				], []);
			},

			async findContas () {
				const limit = this.pagination.limit.value;
				this.pagination.offset = (this.pagination.page - 1) * limit;
				const offset = this.pagination.offset;
				const sortTable = this.sortTable;

				const result = await this.accountService.listAccount({offset, limit, sortTable, inactive: true}) || {};
				this.contas = result.rows || [];

				if (!this.tiposContas.length)
					await this.optionsTiposContas();

				this.linhas = result.rows.reduce((acc, c) => [
					...acc,
					{
						idAccount: c.idAccount,
						cols: [
							c.description,
							c.closeNextDayWeekend ? "Sim" : "Não",
							c.closingDay,
							c.payDay,
							c.sumBalanceDashboard ? "Sim" : "Não",
							c.inactive ? "Sim" : "Não",
							(this.tiposContas.filter(tc => tc.value === c.idAccountClass))[0].text || ""
						]
					}
				], []);

				this.pagination.count = result.count || 0;
				this.pagination.pages = this.pagination.count > limit ? Math.ceil(this.pagination.count / limit) : 1;
				this.loading = false;
			},

			async mostrarEdicao (item_) {
				let item = {};
				if (item_.idAccount)
					[ item ] = this.contas.filter(c => c.idAccount === item_.idAccount);

				const { idAccount, description, closingDay, closeNextDayWeekend, payDay, idAccountClass, sumBalanceDashboard, inactive } = item;

				await this.validateTypeAccount(idAccountClass);

				if (!idAccount)
					this.titleModalEdicao = "Cadastrar Nova Contas";
				else
					this.titleModalEdicao = "Editar Contas";

				this.invalidForm = true;
				this.contaSelecionada.idAccount = idAccount || "";
				this.contaSelecionada.description = description || "";
				this.contaSelecionada.closingDay = closingDay;
				this.contaSelecionada.closeNextDayWeekend = closeNextDayWeekend || false;
				this.contaSelecionada.idAccountClass = idAccountClass;
				this.contaSelecionada.payDay = payDay;
				this.contaSelecionada.sumBalanceDashboard = sumBalanceDashboard;
				this.contaSelecionada.inactive = inactive;
				this.$refs.modalEdicao.show();
			},

			async confirmaApagar (item_) {
				const [ item ] = this.contas.filter((gu) => gu.idAccount === item_.idAccount);
				const deleteConfirmation = (await this.$swal({
					title: "Excluir",
					text: "Tem certeza que deseja remover a conta?",
					type: "error",
					showCancelButton: true,
					cancelButtonColor: "#A6A8AB",
					confirmButtonColor: "#DD6B55",
					imageWidth: 50,
					imageHeight: 50,
					cancelButtonText: "Não",
					confirmButtonText: "Sim",
					reverseButtons: true
				})).value;

				if (deleteConfirmation)
					this.apagar(item.idAccount);
			},

			async salvarEdicao () {
				const config = {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				};
				const payload = {
					idAccount: this.contaSelecionada.idAccount,
					idAccountClass: this.contaSelecionada.idAccountClass,
					description: this.contaSelecionada.description,
					closeNextDayWeekend: this.contaSelecionada.closeNextDayWeekend,
					payDay: this.contaSelecionada.payDay,
					closingDay: this.contaSelecionada.closingDay,
					sumBalanceDashboard: this.contaSelecionada.sumBalanceDashboard,
					inactive: this.contaSelecionada.inactive
				};

				this.$snotify.async("Aguarde...", "Salvando", async () => {
					try {
						await this.accountService.upsertAccount({payload});

						this.fecharModal("modalEdicao");
						this.findContas();

						return {
							title: "Sucesso!",
							body: "Conta salva com sucesso.",
							config
						};
					} catch (error) {
						const msgError = error.response.data.message || "Erro ao tentar salvar conta!";
						throw {
							title: "Falha!",
							body: msgError,
							config
						};
					}
				});
			},

			fecharModal (modal) {
				this.$refs[modal].hide();
			},

			async apagar (idAccount) {
				const config = {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				};

				this.$snotify.async("Aguarde...", "Apagando", async () => {
					try {
						await this.accountService.deleteAccount({idAccount});
						this.findContas();

						return {
							title: "Sucesso!",
							body: "Conta deletada com sucesso.",
							config
						};
					} catch (error) {
						const msgError = error.response.data.message || "Erro ao tentar deletar a conta!";
						throw {
							title: "Falha!",
							body: msgError,
							config
						};
					}
				});
			},

			limparModal () {
				this.contaSelecionada.description = "";
				this.contaSelecionada.closingDay = "";
				this.contaSelecionada.closeNextDayWeekend = false;
				this.contaSelecionada.idAccountClass = null;
				this.contaSelecionada.payDay = "";
				this.contaSelecionada.sumBalanceDashboard = false;
				this.contaSelecionada.inactive = false;
				this.invalidForm = true;
			},

			changeToggle (key) {
				this.contaSelecionada[key] = !this.contaSelecionada[key];
			},

			validateState (ref) {
				this.invalidForm = hasFieldsWithErrors(this.veeFields);
				if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated))
					return !this.veeErrors.has(ref);

				return null;
			},

			async validateTypeAccount (idAccountClass) {
				if (idAccountClass)
					this.closingMonthly = await (this.tiposContasFull.filter(tc => tc.idAccountClass == idAccountClass))[0].closeMonthly;
			}
		}
	};
</script>

<style scoped>
	.toggle-conta {
		/* display: flex; */
		margin-top: 7px;
		/* justify-content: center; */
	}

	label {
		width: 160px;
	}

	.container-head {
		display: flex;
		height: 5rem;
		width: 100%;
	}

	.container-pages {
		display: flex;
		width: 70%;
		margin-top: 1.5rem;
	}

	.containerCard {
		max-width: 90.5%;
	}

	.modal-edicao-label {
		font-family: 'Roboto Condensed Regular';
		font-size: 23px;
	}

	.modal-edicao-input {
		font-family: 'Roboto Condensed Regular';
		font-size: 20px;
	}

	.table-body-detalhe {
		padding: 5px 0;
	}

	.container-add-new-100 {
		width: 100% !important;
	}
</style>
