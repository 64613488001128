import Axios from "axios";
import { createHash } from "crypto";

import environment from "@/environment";

export class AuthService {
	axios;

	constructor () {
		this.axios = Axios.create({ baseURL: `${environment.API}/v1` });
	}

	async login ({ user, senha }) {
		const password = createHash("sha512").update(senha).digest("hex");
		const authentication = await this.axios.post("/auth/login", {
			user,
			password
		});

		const { error } = authentication.data;
		if (error)
			return { success: false, error: error.message };

		return { success: true, error: null, usuarioLogado: authentication.data };
	}

	_getToken () {
		const storage = this.getModuleStorage();
		return storage ? storage.token : null;
	}

	getModuleStorage () {
		const storage = localStorage.getItem("params");
		return storage ? JSON.parse(storage) : null;
	}

	async isLoggedIn () {
		if (!this._getToken())
			return false;

		return await this.isSessionValid();
	}

	async requireLogin () {
		window.location.href = `${window.location.origin}/login?pageToBack=${window.location.pathname}`;
	}

	async logout () {
		try {
			await this.axios.get("logout", {
				headers: {
					Authorization: "Bearer " + this._getToken()
				}
			});
		} catch (error) {
			console.log(error);
		}

		localStorage.clear();
		window.location.href = `${window.location.origin}/login`;
	}

	async isSessionValid () {
		try {
			const response = await this.axios.get("validarSessao", {
				headers: {
					Authorization: "Bearer " + this._getToken()
				}
			});

			return response.status == 200;
		} catch (error) {
			console.log(error);
		}
	}

	async forget (filters) {
		const response = await this.axios.get("/auth/forget", { params: { filters } });
		return response.data;
	}
}
